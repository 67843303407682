import { useEffect, useState } from "react";
import dayjs from 'dayjs';

import MainHeader from "../../../../Header";
import { Layout, Table, Button, Input, Tooltip, Cascader, Alert, Tree, Spin, Segmented, Dropdown, Space } from 'antd';

import TextEdit from "../../TextEdit";

import { auth, db } from "../../../../../firebase";
import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";

const serverUrl = "https://oauth.usedashify.com";
//const serverUrl = "http://127.0.0.1:5000";

const hostUrl = "https://usedashify.com";
//const hostUrl = "http://localhost:3000";

const metricOptionsPaths = [
    {
        value: 'Contacts',
        label: 'Contacts',
        children: [
            { value: 'all_contacts', label: 'All Contacts' },
        ],
    },
]

export default function Hubspot() {
    const [authUrl, setAuthUrl] = useState("");
    const [docId, setDocId] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [metric, setMetric] = useState("");
    const [alert, setAlert] = useState([]);
    const [editId, setEditId] = useState("");
    const [delWidgets, setDelWidgets] = useState([]);


    const [data, setData] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);

    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableTitle, setTableTitle] = useState("");



    useEffect(() => {
        setDocId(localStorage.getItem("docId"));
    }, [])

    const convertDataToTree = (tempData, keyPrefix = '') => {
        if (tempData !== null) return Object.keys(tempData).reduce((acc, key) => {
            const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key;
            const node = {
                title: typeof tempData[key] != 'object' && !Array.isArray(tempData[key])
                    ? <div style={{ display: "flex", flexDirection: "row" }}>
                        <b>{key}:&nbsp;</b>
                        <p style={{ margin: 0, whiteSpace: "nowrap", overflow: "hidden", width: "max-content", textOverflow: "ellipsis" }}>{tempData[key].toString()}</p>
                    </div>
                    : key,

                key: fullKey,

                children: typeof tempData[key] === 'object' || Array.isArray(tempData[key])
                    ? convertDataToTree(tempData[key], fullKey)
                    : null,
            };
            acc.push(node);
            return acc;
        }, []);
    };

    const setDataFunctions = (r) => {
        let m = { "Select All": r };
        setTreeData(convertDataToTree(m));
        setData(m);
    }

    function convertEpochsToHumanReadable(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let value = obj[key];
                if (!isNaN(value) && value > 0) {
                    let date = new Date(parseInt(value));

                    let day = date.getDate().toString().padStart(2, '0');
                    let month = (date.getMonth() + 1).toString().padStart(2, '0');
                    let year = date.getFullYear();
                    let hours = date.getHours() % 12 || 12;
                    let minutes = date.getMinutes().toString().padStart(2, '0');
                    let ampm = date.getHours() < 12 ? 'AM' : 'PM';

                    obj[key] = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
                }
            }
        }
        return obj;
    }

    const updateDocArr = (arr, id) => {
        return arr.filter(function (obj) {
            return obj.id !== id;
        })
    }



    useEffect(() => {
        const fetchContacts = (token) => {
            fetch(`${serverUrl}/fetch_hubspot_contacts?token=${token}`)
                .then(res => res.json())
                .then(res => {
                    if (res.status !== "error") {
                        let newArr = [];
                        for (let i of res.contacts) {
                            let tempId = i["identity-profiles"][0].identities;
                            for (let j of tempId) if ("is-primary" in j) tempId = j;

                            let tempProp = {};
                            for (let j in i.properties) {
                                tempProp[j] = i.properties[j].value
                            }

                            newArr.push(convertEpochsToHumanReadable({
                                addedAt: i.addedAt,
                                identity: tempId.value,
                                ...tempProp
                            }))
                        }



                        let allKeys = [...new Set(newArr.flatMap(obj => Object.keys(obj)))];
                        let resArr = newArr.map(obj =>
                            allKeys.reduce((acc, key) => ({ ...acc, [key]: obj[key] || '' }), {})
                        );

                        setDataFunctions(resArr);
                        setExpandedKeys(["Select All"]);
                    }
                })
                .catch(error => console.log(error));
        }

        const fetchInit = async () => {
            /*let docRef = doc(db, "dashboards", localStorage.getItem("docId"));
            let docSnap = await getDoc(docRef);
            let hubspotData = docSnap.data().hubspot;*/

            if (accessToken !== "") fetchContacts(accessToken) // TEMP
            else {
                setAuthUrl(`https://app.hubspot.com/oauth/authorize?client_id=790f4b38-c4ca-45bd-8582-274f37ab254b&redirect_uri=${hostUrl}/dashboard/integration/hubspot&scope=crm.objects.contacts.read%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.companies.read%20crm.schemas.deals.read%20crm.objects.owners.read`)
            }
        }

        let urlParams = new URLSearchParams(window.location.search);

        if (docId !== "") {
            if (urlParams.size > 0) {
                let codeData = {}
                for (let i of urlParams.entries()) {
                    codeData[i[0]] = i[1];
                }

                if ("edit" in codeData) {
                    async function fetchHubspot() {
                        let docRef = doc(db, "dashboards", docId);
                        let docSnap = await getDoc(docRef);
                        let id = codeData.id;

                        let tempCheckedKeys = [];
                        for (let i of codeData.checkedKeys.split(",")) tempCheckedKeys.push("Select All.0." + i)

                        setDelWidgets(updateDocArr(docSnap.data().widgets, id));
                        setAccessToken(docSnap.data().hubspot.access_token);
                        setRefreshToken(docSnap.data().hubspot.refresh_token);
                        setEditId(id);
                        setTableTitle(codeData.title);
                        fetchContacts(docSnap.data().hubspot.access_token)
                    }
                    fetchHubspot();
                }
                else {
                    fetch(`${serverUrl}/fetch_hubspot_access_token`, {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            grant_type: "authorization_code",
                            client_id: "790f4b38-c4ca-45bd-8582-274f37ab254b",
                            client_secret: "cbb4c126-0af4-4270-9f8f-888d74eedec9",
                            redirect_uri: `${hostUrl}/dashboard/integration/hubspot`,
                            code: codeData.code
                        })
                    })
                        .then(r => r.json())
                        .then(r => {
                            updateDoc(doc(db, "dashboards", docId), {
                                hubspot: {
                                    access_token: r.access_token,
                                    refresh_token: r.refresh_token
                                }
                            });
                            fetchContacts(r.access_token);
                            setAccessToken(r.access_token);
                            setRefreshToken(r.refresh_token);
                        })
                        .catch(error => console.log(error));
                }
            }
            else fetchInit()
        }
    }, [docId])



    const setFinal = async () => {
        let newKeys = [];

        for (let i of checkedKeys) {
            let l = i.split(".");
            let nIdx;
            let hasInt = false;
            for (let j in l) {
                if (/^\+?\d+$/.test(l[j])) {
                    nIdx = j;
                    hasInt = true;
                }
            }
            if (hasInt === true) {
                let m = l.slice(0, nIdx).join(".") + ".0.";
                if (i.includes(m)) newKeys.push(m + i.replace(m, ""));
            }
            else newKeys.push(i);
        }

        if (editId !== "") {
            await updateDoc(doc(db, "dashboards", docId), {
                widgets: [...delWidgets, { type: "hubspot", id: editId, title: tableTitle, metric: metric, checkedKeys: newKeys }]
            });
        }
        else {
            await updateDoc(doc(db, "dashboards", docId), {
                widgets: arrayUnion({
                    type: "hubspot",
                    id: crypto.randomUUID().split("-")[0],
                    title: tableTitle,
                    checkedKeys: newKeys,
                    metric,
                })
            });
        }
        window.location.href = `/dashboard/${docId}`;
    }


    const handleCheck = (newCheckedKeys) => {
        let maxLength = 0;

        for (let i of newCheckedKeys) {
            let l = i.split(".").length;
            if (l > maxLength) maxLength = l;
        }

        for (let i of newCheckedKeys) {
            let l = i.split(".");
            if (l.length !== maxLength) newCheckedKeys = newCheckedKeys.filter(e => e !== i)
        }

        let ktc = "";
        let changeArr;
        let q;


        if (checkedKeys.length > newCheckedKeys.length) {
            changeArr = checkedKeys.filter(x => !newCheckedKeys.includes(x));
            q = "del";
        }
        else if (checkedKeys.length < newCheckedKeys.length) {
            changeArr = newCheckedKeys.filter(x => !checkedKeys.includes(x));
            q = "add";
        }


        if (q === "del") {
            for (let i of changeArr) {
                let l = i.split(".");
                let m = l[l.length - 1];
                if (!/^\+?\d+$/.test(m)) ktc = m;
                else ktc = l[l.length - 2];
            }

            for (let i of newCheckedKeys) {
                let l = i.split(".");
                if (l[l.length - 1] === ktc || l[l.length - 2] === ktc) newCheckedKeys = newCheckedKeys.filter(e => e !== i)
            }
        }

        else if (q === "add") {
            for (let i of changeArr) {
                let l = i.split(".");
                let m = l[l.length - 1];
                if (!/^\+?\d+$/.test(m)) ktc = m;
                else ktc = l[l.length - 2];
            }

            for (let i of changeArr) {
                let l = i.split(".");
                let t = l.slice(0, l.length - 2).join(".");
                let n = getKey(t);
                if (n !== undefined) for (let j = 0; j < n.length; j++) {
                    let m = `${t}.${j}.${l[l.length - 1]}`;
                    if (j !== parseInt(l[l.length - 2]) && !newCheckedKeys.includes(m)) newCheckedKeys.push(m)
                }
            }
        }

        setCheckedKeys(newCheckedKeys);
        let columns = [];
        let tempData = [];

        let tempObj = {}
        let tempCheckedKeys = [...newCheckedKeys]

        tempCheckedKeys.push(Array(maxLength + 1).join("dashify.").slice(0, -1));

        for (let i in tempCheckedKeys) {
            let l = tempCheckedKeys[i].split(".");
            let title = l[maxLength - 1];

            let colObj = {
                title: title,
                dataIndex: title, key: title, ellipsis: { showTitle: false },
                render: (e) => (
                    <Tooltip placement="topLeft" title={e}>{e}</Tooltip>
                ),
            }

            if (l.length === maxLength) {
                tempObj[title] = getKey(tempCheckedKeys[i]);
                let tt = tempObj[title];
                if (tt != undefined) tempObj[title] = tt.toString();

                i = parseInt(i);
                let l1 = tempCheckedKeys[i + 1];

                if (l1 !== undefined) {
                    l1 = l1.split(".")
                    if (l[l.length - 2] !== l1[l1.length - 2]) {
                        let j = tempData.findIndex(e => e.key.includes(l.slice(0, l.length - 1).join(".")));
                        if (j > -1) tempData[j][title] = tt.toString();
                        else {
                            tempObj["key"] = tempCheckedKeys[i];
                            tempData.push(tempObj);
                        }
                        tempObj = {};
                    }
                }

                if (!columns.some(e => e.dataIndex === title)) columns.push(colObj)
            }
        }

        columns = columns.filter(e => e.dataIndex !== 'dashify');

        for (let i in columns) {
            let t = columns[i].title;
            columns[i].title = <Input placeholder="Enter Column Name" value={t} bordered={false} onChange={(e) => { editColumnName(columns, i, e.target.value); }} />
        }

        setTableData(tempData);
        setTableColumns(columns);
    }

    const editColumnName = (arr, idx, tNew) => {
        let newArr = [...arr];
        newArr[idx]["title"] = <Input placeholder="Enter Column Name" value={tNew} bordered={false} onChange={(e) => { editColumnName(arr, idx, e.target.value); }} />;
        setTableColumns(newArr);
    }

    const getKey = (key) => {
        let value = data;
        for (let segment of key.split('.')) {
            if (typeof value === 'object' && value !== null && segment in value) value = value[segment]
            else return undefined;
        }

        return value;
    };

    return (
        <Layout style={{ height: "100vh" }}>
            <Layout.Header>
                <MainHeader />
            </Layout.Header>

            <Layout.Content>
                <div style={{ padding: 20 }}>
                    {
                        authUrl !== ""
                            ? <a href={authUrl}>
                                <Button type="primary">Connect Hubspot</Button>
                            </a>
                            : null
                    }

                    {
                        "Select All" in data
                            ? <>
                                <div style={{ display: "flex", flexDirection: "row", height: "70vh" }}>
                                    <div style={{ overflow: "auto", height: "100%", width: "30%" }}>
                                        <p>Select Metric</p>

                                        <Cascader maxTagCount="responsive" style={{ width: "100%" }} onChange={(e) => { setMetric(e[e.length - 1]) }} options={metricOptionsPaths} />

                                        {
                                            treeData.length > 0
                                                ? <>
                                                    <p>Check fields to be displayed</p>

                                                    <Tree checkable style={{ overflow: "auto" }} treeData={treeData} expandedKeys={expandedKeys}
                                                        onExpand={(e) => { setExpandedKeys(e); }} checkedKeys={checkedKeys} onCheck={handleCheck} /></>
                                                : null
                                        }
                                    </div>


                                    <div style={{ width: 2, height: "100%", backgroundColor: "#eee", marginLeft: 10, marginRight: 20 }} />

                                    <div style={{ overflow: "auto", height: "100%", marginBottom: 20, width: "100%" }}>
                                        <Input style={{ marginBottom: 10 }} placeholder="Enter Widget Title" value={tableTitle} onChange={(e) => setTableTitle(e.target.value)} />

                                        <p>Table Preview:</p>
                                        <Table dataSource={tableData} columns={tableColumns} />
                                    </div>
                                </div>
                            </>
                            : null
                    }

                    {
                        alert.length > 0
                            ? <Alert style={{ marginTop: 30 }} message={`Error ${alert[0]}`} description={alert[1]} type="error" closable />
                            : null
                    }

                    {
                        tableColumns.length > 0
                            ? <div>
                                <Button type="primary" style={{ marginRight: 10 }} onClick={setFinal}>Ok</Button>
                                <a href={`/dashboard/${docId}`}>
                                    <Button>Cancel</Button>
                                </a>
                            </div>
                            : null
                    }
                </div>
            </Layout.Content>
        </Layout>

    );
}