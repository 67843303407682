import { useEffect, useState } from "react";
import styled from "styled-components";
import TimezoneSelect from 'react-timezone-select'

import { Modal, Input } from 'antd';

export default function DateTimeModal({ isModalOpen, setIsModalOpen, setParentData, editData }) {
    const [title, setTitle] = useState(editData !== null ? editData.title : "");
    const [selectedTimezone, setSelectedTimezone] = useState(editData !== null ? editData.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [active, setActive] = useState(false);

    const onOk = () => {
        let t = typeof selectedTimezone === "object" ? selectedTimezone.value : selectedTimezone;
        if (editData !== null) setParentData({
            ...editData,
            title,
            timezone: t,
            edit: true
        });

        else setParentData({
            type: "datetime",
            id: crypto.randomUUID().split("-")[0],
            title: title,
            timezone: t,
        });
        setIsModalOpen(false);
    }

    return (
        <Modal title={editData !== null ? "Edit Widget" : "Add Widget"} width="50vw" style={{ overflow: "hidden" }}
            open={isModalOpen} onOk={onOk} onCancel={() => { setIsModalOpen(false); }}>

            <Input style={{ marginBottom: 10 }} placeholder="Enter Widget Title" value={title} onChange={(e) => setTitle(e.target.value)} />

            <h4 style={{ marginBottom: 5 }}>Timezone</h4>
            <div style={{ height: active ? 400 : null }}>
                <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} onMenuOpen={() => { setActive(true); }} onMenuClose={() => { setActive(false); }} />
            </div>
        </Modal>
    );
}
