import React, { useEffect, useState } from 'react';

import { Button, Layout, Modal, Input, List, Alert, Spin, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { useAuth } from "../Auth";
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import { db } from '../firebase';
import { collection, addDoc, doc, updateDoc, getDocs, query, where, deleteDoc, serverTimestamp, deleteField } from "firebase/firestore";

import MainHeader from './Header';

export default function MainMenu() {
    const [dashboards, setDashboards] = useState([]);
    const [sharedDash, setShareDash] = useState([]);
    const [alertVisible, setAlertVisible] = useState(false);
    const [userTier, setUserTier] = useState("");
    const [subId, setSubId] = useState(null);
    const [userDocId, setUserDocId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [delMode, setDelMode] = useState(false);

    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        let authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            navigate('/login')
        }
    }, []);

    useEffect(() => {
        const checkPlanStatus = () => {
            var urlencoded = new URLSearchParams();
            urlencoded.append("grant_type", "client_credentials");

            fetch("https://api-m.sandbox.paypal.com/v1/oauth2/token", {
                method: "POST",
                headers: {
                    "Authorization": "Basic QWJDbXNibUs1Z0Q1U2FISHJhRURaWUJDU3B3SF9mRjdqNHp6RHhEbkRQZ2F3Z0UwdVNuRjhWbXJiZjBZOVlibHZ3d2prTzBDSDBicGQxd1g6RUpPN0RWVjZNSUJia0E2RzBmU3ZMZGVxUlBuWTNkSXA5ZGhHUUl3TmNCTHhpMzh3T2lad2V6dXQxaUluRDhydWo1WFUwY2s2VllmRkc3RC0=",
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: urlencoded,
                redirect: "follow"
            })
                .then(res => res.json())
                .then(res => {
                    fetch(`https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${subId}`, {
                        method: "GET",
                        headers: {
                            "Authorization": `Bearer ${res.access_token}`
                        },
                        redirect: "follow"
                    })
                        .then(res => res.json())
                        .then(res => {
                            if (res.status !== "ACTIVE") {
                                updateDoc(doc(db, "accounts", userDocId), {
                                    tier: "free",
                                    subscriptionID: deleteField(),
                                    purchaseDate: deleteField(),
                                    delMode: true,
                                });
                                setUserTier("free");
                                if (dashboards.length > 1) setDelMode(true);
                            }
                            else {
                                updateDoc(doc(db, "accounts", userDocId), {
                                    delMode: deleteField(),
                                })
                            }
                            setLoading(false);
                        })
                        .catch(error => console.log('error', error));
                })
                .catch(error => console.log('error', error));
        }

        if (subId !== null) checkPlanStatus();
    }, [subId])

    useEffect(() => {
        const getFirestoreData = async () => {
            let q = query(collection(db, "dashboards"), where("owner", "==", currentUser.uid));
            let querySnapshot = await getDocs(q);
            let tempBoards = [];

            querySnapshot.forEach((doc) => {
                tempBoards.push({
                    id: doc.id,
                    name: doc.data().name,
                    editable: false,
                });
            });

            setDashboards(tempBoards);

            let tempUserDocId;

            q = query(collection(db, "accounts"), where("userId", "==", currentUser.uid));
            querySnapshot = await getDocs(q);

            let toChange = { lastLogin: serverTimestamp() };

            querySnapshot.forEach((doc) => {
                tempUserDocId = doc.id;
                setUserDocId(doc.id);
                setUserTier(doc.data().tier);

                if (["starter", "premium"].includes(doc.data().tier)) setSubId(doc.data().subscriptionID)
                else {
                    setLoading(false);
                    if (tempBoards.length > 1) {
                        setDelMode(true);
                        toChange["delMode"] = true;
                    }
                }
            });

            q = query(collection(db, "dashboards"), where("collaborators", "array-contains", currentUser.uid));
            querySnapshot = await getDocs(q);
            let tempShared = [];

            querySnapshot.forEach(d => {
                tempShared.push({
                    id: d.id,
                    name: d.data().name,
                })
            })
            setShareDash(tempShared);
            updateDoc(doc(db, "accounts", tempUserDocId), toChange);
        }

        if (currentUser !== null) getFirestoreData();
    }, [currentUser])

    const createDashboard = async () => {
        if (dashboards.length > (dashLimit() - 1)) setAlertVisible(true)
        else {
            try {
                let docRef = await addDoc(collection(db, "dashboards"), {
                    owner: currentUser.uid,
                    name: "Dashboard",
                    createdAt: serverTimestamp(),
                });
                navigate(`/dashboard/${docRef.id}`);
            }
            catch (e) {
                console.error("Error adding document: ", e);
            }
        }
    }

    const showDeleteConfirm = (itemId) => {
        Modal.confirm({
            title: 'Confirm Deletion',
            content: 'Are you sure you want to delete this dashboard? This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => handleDeleteItem(itemId),
        });
    };

    const handleEditToggle = (itemId, editDone) => {
        setDashboards((prevItems) => prevItems.map((item) => item.id === itemId ? { ...item, editable: !item.editable } : item));

        if (editDone) updateDoc(doc(db, "dashboards", itemId), { name: dashboards.find((item) => item.id === itemId).name });
    };

    const handleTextChange = (itemId, newText) => {
        setDashboards((prevItems) => prevItems.map((item) => item.id === itemId ? { ...item, name: newText } : item));
    };

    const handleDeleteItem = async (itemId) => {
        if (dashboards.length === (dashLimit() + 1)) {
            setDelMode(false);
            updateDoc(doc(db, "accounts", userDocId), { delMode: deleteField() });
        }
        setDashboards((prevItems) => prevItems.filter((item) => item.id !== itemId));
        await deleteDoc(doc(db, "dashboards", itemId));

    };

    const dashLimit = () => {
        let lim = 0;

        if (userTier === "free") lim = 1
        else if (userTier === "starter") lim = 5
        else if (userTier === "premium") lim = 20

        return lim;
    }

    return (
        <Layout style={{ height: "100vh" }}>
            <Helmet>
                <title>Your Dashboards | Dashify</title>
                <meta property="og:title" content="Your Dashboards | Dashify" />
            </Helmet>

            <Layout.Header>
                <MainHeader />
            </Layout.Header>

            <Layout.Content style={{ padding: 10, height: "100%", overflow: "auto" }}>
                <Button disabled={userTier === ""} type="primary" onClick={createDashboard}>New Dashboard</Button>

                <h3 style={{ marginTop: 20, marginBottom: 0, fontWeight: "bold" }}>Your Dashboards</h3>

                {
                    delMode
                        ? <Alert message="Limit Reached" type="error" style={{ marginTop: 10, marginBottom: 10 }}
                            description={<p>You have exceeded your dashboard limit according to your plan. Delete {dashboards.length - dashLimit()} dashboards or&nbsp;
                                <Button style={{ padding: 0, marginTop: 10 }} type="link" href="/pricing">upgrade your plan</Button> now
                            </p>} />
                        : null
                }

                {
                    loading
                        ? <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
                            <Spin size="large" />
                        </div>
                        : <>
                            <List dataSource={dashboards} locale={{ emptyText: "Create a dashboard to get started" }} renderItem={(item) => (
                                <List.Item actions={[
                                    <Button key="edit" onClick={() => handleEditToggle(item.id, item.editable)} icon={item.editable ? null : <EditOutlined />}>
                                        {item.editable ? 'Save' : 'Edit'}
                                    </Button>,

                                    <Button key="delete" onClick={() => showDeleteConfirm(item.id)} danger icon={<DeleteOutlined />}>Delete</Button>,
                                ]}>
                                    {
                                        item.editable
                                            ? <Input value={item.name} onChange={(e) => handleTextChange(item.id, e.target.value)} />
                                            : <Link style={{ color: "#1677ff" }} to={`/dashboard/${item.id}`}>{item.name}</Link>
                                    }
                                </List.Item>
                            )} />

                            {
                                sharedDash.length > 0
                                    ? <>
                                        <h3 style={{ marginBottom: 12, fontWeight: "bold" }}>Shared Dashboards</h3>

                                        {
                                            sharedDash.map((item, i) =>
                                                <Link key={i} style={{ color: "#1677ff", marginBottom: 12, display: "block" }} to={`/dashboard/${item.id}`}>{item.name}</Link>
                                            )
                                        }
                                    </>
                                    : null
                            }
                        </>
                }


                {
                    alertVisible
                        ? <Alert message="Limit Reached" type="error" closable onClose={() => { setAlertVisible(false); }}
                            description="You have reached your limit of creating dashboards. Upgrade your plan to create additional dashboards." />
                        : null
                }
            </Layout.Content>
        </Layout>
    );
};