import React, { useState, useEffect } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Button, Result, Spin } from 'antd';

import { useAuth } from '../../Auth';
import { db } from '../../firebase';
import { collection, doc, updateDoc, getDocs, query, where, serverTimestamp } from "firebase/firestore";

export default function PayPalCheckout({ plan }) {
    const { currentUser } = useAuth();
    const [{ isPending }] = usePayPalScriptReducer();
    const [subId, setSubId] = useState(null);

    const paypalSubscribe = (data, actions) => {
        let pid;

        
        //LIVE PLAN IDS
        if (plan === "starter") pid = "P-43P73748JC024274UMZH2E4I"
        else if (plan === "premium") pid = "P-16E34811YB535880RMZH2FNA"



        /*
        //SANDBOX PLAN IDS
        if (plan === "starter") pid = "P-7R476924M6102290RMZCOEBI"
        else if (plan === "premium") pid = "P-77U91309972665904MZCOFGY"
        */

        return actions.subscription.create({
            "plan_id": pid
        });
    };

    useEffect(() => {
        if (currentUser !== null) localStorage.setItem("currentUserId", currentUser.uid)
    }, [])

    const paypalOnError = (err) => {
        console.log(err)
    }
    const paypalOnCancel = () => {
        console.log("Cancelled")
    }

    const paypalOnApprove = async (data, detail) => {
        console.log("Purchase Successful")

        let cid = localStorage.getItem("currentUserId");
        let userDocId;

        let q = query(collection(db, "accounts"), where("userId", "==", cid));
        let querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            userDocId = doc.id;
        });

        updateDoc(doc(db, "accounts", userDocId), {
            tier: plan,
            purchaseDate: serverTimestamp(),
            subscriptionID: data.subscriptionID
        });

        setSubId(data.subscriptionID);
    };

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
            {
                subId !== null
                    ? <Result
                        status="success"
                        title={`Thank you for purchasing the ${plan[0].toUpperCase() + plan.slice(1)} plan!`}
                        extra={[
                            <Button type="link" href="/menu">
                                Go to Menu
                            </Button>
                        ]}
                    />
                    : isPending
                        ? <Spin size="large" style={{ marginTop: 50 }} />
                        : <div style={{ width: "50%", textAlign: "center" }}>
                            <h2 style={{ fontSize: 20, marginBottom: 10 }}>Select a payment method to purchase the {plan[0].toUpperCase() + plan.slice(1)} plan</h2>

                            <p style={{ fontSize: 17, marginBottom: 30 }}>Having trouble?&nbsp;
                                <a href="/contact" target="_blank" style={{ color: "blue" }}>Contact us</a>
                                &nbsp;now</p>

                            <PayPalButtons style={{ layout: "vertical", shape: "pill", disableMaxWidth: true }} createSubscription={paypalSubscribe} onApprove={paypalOnApprove}
                                catchError={paypalOnError} onError={paypalOnError} onCancel={paypalOnCancel} />
                        </div>
            }
        </div>
    );
}
