import { useEffect, useState } from "react";
import styled from "styled-components";

import { Modal, Input, Button, Alert, Table, Tooltip, Spin } from 'antd';

export default function Csv({ isModalOpen, setIsModalOpen, setParentData, editData }) {
    const [tableTitle, setTableTitle] = useState(editData !== null ? editData.title : "");
    const [endpoint, setEndpoint] = useState(editData !== null ? editData.endpoint : "");
    const [error, setError] = useState("");
    const [tableColumns, setTableColumns] = useState(editData !== null ? editData.columns : []);
    const [tableData, setTableData] = useState(editData !== null ? editData.data : []);
    const [loading, setLoading] = useState(false);

    const getEndpointJson = () => {
        setLoading(true);
        // https://people.sc.fsu.edu/~jburkardt/data/csv/addresses.csv
        // https://cdn.wsform.com/wp-content/uploads/2020/06/industry_sic.csv

        fetch(`https://oauth.usedashify.com/fetch_csv`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "csv_url": endpoint })
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    res.json().then(r => {
                        setError(`Error ${r.code}: ${r.message}`);

                    })
                    throw new Error()
                }
            })
            .then(res => {
                setError("");
                let tempColumns = [];
                let tempData = [];
                let h = [];


                for (let i in res[0]) {
                    h.push("h" + i.toString())
                    tempColumns.push({
                        title: res[0][i],
                        dataIndex: "h" + i.toString(),
                        key: res[0][i],
                        ellipsis: { showTitle: false },
                        render: (e) => <Tooltip placement="topLeft" title={e} style={{ display: "block", position: "relative" }}>{e}</Tooltip>
                    });
                }
                let t1 = res.slice(1, res.length);
                for (let i in t1) {
                    let t = { key: `r${parseInt(i) + 2}` };
                    for (let j in t1[i]) t[h[j]] = t1[i][j];
                    tempData.push(t);
                }

                for (let i in tempColumns) {
                    let t = tempColumns[i].title;
                    tempColumns[i].title = <Input placeholder="Enter Column Name" value={t} bordered={false} onChange={(e) => { editColumnName(tempColumns, i, e.target.value); }} />
                }
                setTableColumns(tempColumns);
                setTableData(tempData);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const editColumnName = (arr, idx, tNew) => {
        let newArr = [...arr];
        newArr[idx]["title"] = <Input placeholder="Enter Column Name" value={tNew} bordered={false} onChange={(e) => { editColumnName(arr, idx, e.target.value); }} />;
        setTableColumns(newArr);
    }

    const onOk = () => {
        let newArr = [];
        for (let i of tableColumns) {
            newArr.push({ ...i, title: typeof i.title === "string" ? i.title : i.title.props.value })
        }

        if (editData !== null) setParentData({
            ...editData,
            title: tableTitle,
            endpoint: endpoint,
            columns: newArr,
            data: tableData,
            edit: true
        });

        else setParentData({
            type: "csv",
            id: crypto.randomUUID().split("-")[0],
            title: tableTitle,
            endpoint: endpoint,
            columns: newArr,
            data: tableData
        });
        setIsModalOpen(false);
    }

    return (
        <Modal title={editData !== null ? "Edit Widget" : "Add Widget"} width="50vw" style={{ overflow: "hidden" }}
            okButtonProps={{ disabled: tableColumns.length === 0 || tableData.length === 0 }} open={isModalOpen} onOk={onOk} onCancel={() => { setIsModalOpen(false); }}>
            <Input style={{ marginBottom: 10 }} placeholder="Enter Widget Title" value={tableTitle} onChange={(e) => setTableTitle(e.target.value)} />

            <h4 style={{ marginBottom: 5 }}>CSV URL</h4>
            <Input placeholder="Enter CSV URL" value={endpoint} onChange={(e) => setEndpoint(e.target.value)} />

            <center style={{ marginTop: 20 }}>
                <Button onClick={getEndpointJson} disabled={endpoint.trim() === "" || loading}>Fetch Sheet</Button>
            </center>

            {
                loading
                    ? <center style={{ marginTop: 20 }}>
                        <Spin />
                    </center>
                    : null
            }

            {
                tableColumns.length > 0
                    ? <div>
                        <h4 style={{ marginBottom: 5 }}>Table Preview:</h4>
                        <Table columns={tableColumns} dataSource={tableData} />
                    </div>
                    : null
            }

            {
                error !== ""
                    ? <Alert message="Error" description={error} type="error" style={{ marginTop: 10 }} />
                    : null
            }

        </Modal>
    );
}
