import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';

import Header from '../components/header'
import Footer from '../components/footer'
import PayPalCheckout from '../components/checkout';




//LIVE CREDENTIALS
const initialOptions = {
    "client-id": "AbKnEfJJl5yt6MG18L-iQd6pDKZhZegFMSRhwHGQkkIvaJ5-QgyA-530yk00OoGVcRVkB3oj9fZemZFT",
    vault: true,
    intent: "subscription"
};



/*
//SANDBOX CREDENTIALS
const initialOptions = {
    "client-id": "AbCmsbmK5gD5SaHHraEDZYBCSpwH_fF7j4zzDxDnDPgawgE0uSnF8Vmrbf0Y9YblvwwjkO0CH0bpd1wX",
    vault: true,
    intent: "subscription"
};
*/



export default function Billing() {
    const [plan, setPlan] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        let authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            navigate('/login')
        }
        else {
            let t = new URLSearchParams(window.location.search).get("plan")
            if (["starter", "premium"].includes(t)) setPlan(t)
            else navigate('/pricing')
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>Billing | Dashify</title>
                <meta property="og:title" content="Billing | Dashify" />
            </Helmet>

            <Header />

            <div className="container pt-20 lg:pt-[120px] mb-20 h-[100vh]">
                <h2 className="font-bold text-3xl sm:text-4xl md:text-[42px] text-dark mt-5 mb-10 text-center">Billing</h2>

                <PayPalScriptProvider options={initialOptions}>
                    <PayPalCheckout plan={plan} />
                </PayPalScriptProvider>
            </div>

            <Footer />
        </div>
    )
}

