import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/header';
import Footer from '../components/footer';

import { db } from '../../firebase';
import { collection, getDocs, query, where } from "firebase/firestore";

import { useAuth } from '../../Auth';
import { useNavigate } from 'react-router-dom';

export default function Pricing() {
    const { currentUser } = useAuth();
    const [currentPlan, setCurrentPlan] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getFirestoreData = async () => {
            let q = query(collection(db, "accounts"), where("userId", "==", currentUser.uid));
            let querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                setCurrentPlan(doc.data().tier)
            });
        }

        if (currentUser !== null) getFirestoreData();
    }, [currentUser])


    const purchase = (plan) => {
        if ("auth_token" in localStorage) {
            if(currentPlan !== plan) navigate("/billing?plan=" + plan);
            else navigate("/settings")
        }
        else navigate("/signup?ref=billing&plan=" + plan)
    }

    return (
        <div>
            <Helmet>
                <title>Pricing | Dashify</title>
                <meta property="og:title" content="Pricing | Dashify" />
            </Helmet>

            <Header />

            <section id="pricing" className="relative z-20 overflow-hidden bg-white pt-32 pb-12 lg:pt-[180px] lg:pb-[90px]">
                <div className="container mx-auto">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full px-4">
                            <div className="mx-auto mb-[60px] max-w-[510px] text-center">
                                <h2 className="mb-3 text-3xl font-bold text-dark m:text-4xl md:leading-[1.2] md:text-[40px]">
                                    Choose the Perfect Plan
                                </h2>
                                <p className="text-base text-body-color">
                                    Pricing built for businneses of all sizes.<br /> Always know what you pay for.<br />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center -mx-4">
                        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div
                                className="relative z-10 px-8 py-10 mb-10 overflow-hidden bg-white rounded-xl shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-14">
                                <span className="block mb-5 text-xl font-medium text-dark">
                                    Free
                                </span>
                                <h2 className="mb-11 text-4xl font-semibold text-dark xl:leading-[1.21] xl:text-[42px]">
                                    <span className="text-xl font-medium">$</span>
                                    <span className="mx-1">0</span>
                                    <span className="text-base font-normal text-body-color">
                                        Per Month
                                    </span>
                                </h2>
                                <div className="mb-[50px]">
                                    <h5 className="mb-5 text-lg font-medium text-dark">Features</h5>
                                    <div className="flex flex-col gap-[14px]">
                                        <p className="text-base text-body-color">
                                            Create your first dashboard for free
                                        </p>
                                        <p className="text-base text-body-color">
                                            Share dashboards via sharing links
                                        </p>
                                        <p className="text-base text-body-color">
                                            All data sources
                                        </p>
                                        <p className="text-base text-body-color">
                                            Email support
                                        </p>
                                    </div>
                                </div>
                                <a href="/signup"
                                    className="inline-block py-3 text-base font-medium text-center text-white transition rounded-md bg-primary px-7 hover:bg-blue-dark">
                                    Sign Up
                                </a>
                            </div>
                        </div>


                        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div
                                className="relative z-10 px-8 py-10 mb-10 overflow-hidden bg-white rounded-xl shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-14">
                                <p
                                    className="absolute right-[-50px] top-[60px] inline-block -rotate-90 rounded-tl-md rounded-bl-md bg-primary py-2 px-6 text-base font-medium text-white">
                                    Recommended
                                </p>
                                <span className="block mb-5 text-xl font-medium text-dark">
                                    Starter
                                </span>
                                <h2 className="mb-11 text-4xl font-semibold text-dark xl:leading-[1.21] xl:text-[42px]">
                                    <span className="text-xl font-medium">$</span>
                                    <span className="mx-1">97</span>
                                    <span className="text-base font-normal text-body-color">
                                        Per Month
                                    </span>
                                </h2>
                                <div className="mb-[50px]">
                                    <h5 className="mb-5 text-lg font-medium text-dark">Features</h5>
                                    <div className="flex flex-col gap-[14px]">
                                        <p className="text-base text-body-color">
                                            All features of Free plan
                                        </p>
                                        <p className="text-base text-body-color">
                                            Create up to 5 dashboards
                                        </p>
                                        <p className="text-base text-body-color">
                                            Unlimited widgets
                                        </p>
                                        <p className="text-base text-body-color">
                                            All widget features
                                        </p>
                                        <p className="text-base text-body-color">
                                            Upto 3 users
                                        </p>
                                        <p className="text-base text-body-color">
                                            Custom branding
                                        </p>
                                        <p className="text-base text-body-color">
                                            Live priority support
                                        </p>
                                    </div>
                                </div>
                                <button onClick={() => { purchase("starter") }} className="inline-block py-3 text-base font-medium text-center text-white transition rounded-md bg-primary px-7 hover:bg-dark">
                                    Purchase Now
                                </button>
                            </div>
                        </div>


                        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                            <div
                                className="relative z-10 px-8 py-10 mb-10 overflow-hidden bg-white rounded-xl shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-14">
                                <span className="block mb-5 text-xl font-medium text-dark">
                                    Premium
                                </span>
                                <h2 className="mb-11 text-4xl font-semibold text-dark xl:leading-[1.21] xl:text-[42px]">
                                    <span className="text-xl font-medium">$</span>
                                    <span className="mx-1">297</span>
                                    <span className="text-base font-normal text-body-color">
                                        Per Month
                                    </span>
                                </h2>
                                <div className="mb-[50px]">
                                    <h5 className="mb-5 text-lg font-medium text-dark">Features</h5>
                                    <div className="flex flex-col gap-[14px]">
                                        <p className="text-base text-body-color">
                                            All features of Starter plan
                                        </p>
                                        <p className="text-base text-body-color">
                                            Create upto 20 dashboards
                                        </p>
                                        <p className="text-base text-body-color">
                                            Advanced dashboard theming
                                        </p>
                                        <p className="text-base text-body-color">
                                            Upto 10 users
                                        </p>
                                    </div>
                                </div>
                                <button onClick={() => { purchase("premium") }} className="inline-block py-3 text-base font-medium text-center text-white transition rounded-md bg-primary px-7 hover:bg-dark">
                                    Purchase Now
                                </button>
                            </div>
                        </div>
                    </div>

                    <p className="text-center text-base text-body-color mt-5">
                        Want a custom plan suited to your business?&nbsp;
                        <a className="text-blue-600 hover:text-blue-800" href="/contact">Contact us</a> now
                    </p>
                </div>
            </section>

            <Footer />
        </div>
    )
}

