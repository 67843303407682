import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'

export default function BlogSelect() {
    return (
        <div>
            <Helmet>
                <title>Blogs | Dashify</title>
                <meta property="og:title" content="Blogs | Dashify" />
            </Helmet>

            <Header />

            <div className="container pt-20 lg:pt-[120px] mb-20 h-[100vh]">
                <h2 className="font-bold text-3xl sm:text-4xl md:text-[42px] text-dark mt-5 mb-10 text-center">Blog</h2>

                <div className="">
                    <a href="/blog/create-a-youTube-analytics-dashboard-to-impress-your-sponsors" className="w-full block md:w-1/2 lg:w-1/2">
                        <div className="border rounded-md p-4">
                            <img className="rounded-lg mb-5" src="/landing/Youtube Dashboard.PNG" alt="Youtube Dashboard" />
                            <p>Create a YouTube Analytics Dashboard to Impress your Sponsors</p>
                            <p className="text-sm text-body-color">
                                Saaheer Purav
                                <br />
                                14th April 2024
                            </p>
                        </div>
                    </a>
                </div>
            </div>

            <Footer />
        </div>
    )
}

