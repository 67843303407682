import { useEffect, useState } from "react";
import { Tooltip } from 'antd';
import dayjs from 'dayjs';

import { db } from "../../../firebase";
import { doc, updateDoc, getDoc, query, collection, where, getDocs } from "firebase/firestore";

const metricOptions = {
    day: "Date",
    views: "Views",
    redViews: "Views by Premium Members",
    estimatedMinutesWatched: "Estimated Minutes Watched",
    estimatedRedMinutesWatched: "Estimated Minutes Watched by Premium Members",
    averageViewDuration: "Average View Duration",
    averageViewPercentage: "Average View Percentage",
    comments: "Comments",
    likes: "Likes",
    dislikes: "Dislikes",
    shares: "Shares",
    subscribersGained: "Subscribers Gained",
    subscribersLost: "Subscribers Lost",
    videosAddedToPlaylists: "Videos Added To Playlists",
    videosRemovedFromPlaylists: "Videos Removed From Playlists",
    averageTimeInPlaylist: "Average Time In Playlist",
    playlistAverageViewDuration: "Playlist Average View Duration",
    playlistEstimatedMinutesWatched: "Playlist Estimated Minutes Watched",
    playlistSaves: "Playlist Saves",
    playlistStarts: "Playlist Starts",
    playlistViews: "Playlist Views",
    viewsPerPlaylistStart: "Views Per Playlist Start",
    annotationImpressions: "Annotation Impressions",
    annotationClickableImpressions: "Annotation Clickable Impressions",
    annotationClicks: "Annotation Clicks",
    annotationClickThroughRate: "Annotation Click Through Rate",
    annotationClosableImpressions: "Annotation Closable Impressions",
    annotationCloses: "Annotation Closes",
    annotationCloseRate: "Annotation Close Rate",
    cardImpressions: "Card Impressions",
    cardClicks: "Card Clicks",
    cardClickRate: "Card Click Rate",
    cardTeaserImpressions: "Card Teaser Impressions",
    cardTeaserClicks: "Card Teaser Clicks",
    cardTeaserClickRate: "Card Teaser Click Rate",
    audienceWatchRatio: "Audience Watch Ratio",
    relativeRetentionPerformance: "Relative Retention Performance",
    estimatedRevenue: "Estimated Revenue",
    estimatedAdRevenue: "Estimated Ad Revenue",
    estimatedRedPartnerRevenue: "Estimated Red Partner Revenue",
    grossRevenue: "Gross Revenue",
    cpm: "CPM",
    adImpressions: "Ad Impressions",
    monetizedPlaybacks: "Monetized Playbacks",
    playbackBasedCpm: "Playback Based CPM"
}

const serverUrl = "https://oauth.usedashify.com";
//const serverUrl = "http://127.0.0.1:5000";

const rangeOptions = [
    { value: 'last_7_d', label: "Last 7 days" },
    { value: 'last_14_d', label: "Last 14 days" },
    { value: 'last_m', label: "Last month" },
    { value: 'last_3_m', label: "Last 3 months" },
    { value: 'last_y', label: "Last year" },
    { value: 'custom_timeframe', label: "Custom Timeframe" },
    { value: 'custom_date', label: "Custom Date" },
]


export default async function FetchWidgetData(data, airtableData, youtubeData, hubspotData, appendDataSources, setParentData) {
    let ytNewToken = "";
    let reauth = false;

    if (data.length === 0) setParentData(null)
    else for (let i of data) {
        if (i.type === "table" || i.type === "chart") {
            fetch(i.endpoint, i.fetchOptions)
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        return res.text().then(text => { throw new Error(text) })
                    }
                })
                .then(r => {
                    r = { "Select All": r }
                    appendDataSources({ endpoint: i.endpoint, data: r });

                    const getKey = (key) => {
                        let value = r;
                        if (key !== "") for (let segment of key.split('.')) {
                            if (typeof value === 'object' && value !== null && segment in value) value = value[segment]
                            else return undefined;
                        }
                        return value;
                    };

                    if (i.type === "table") {
                        let maxLength = 0;
                        let newCheckedKeys = [];
                        let hasInt = true;

                        for (let k of i.checkedKeys) {
                            let l = k.split(".");
                            if (l.length > maxLength) maxLength = l.length;
                            let t = l.slice(0, l.length - 2).join(".");
                            if (t === "") {
                                hasInt = false;
                                newCheckedKeys.push(l.join("."));
                            }
                            else {
                                let n = getKey(t);
                                if (n !== undefined) for (let j = 0; j < n.length; j++) {
                                    let m = `${t}.${j}.${l[l.length - 1]}`;
                                    if (!newCheckedKeys.includes(m)) newCheckedKeys.push(m)
                                }
                            }
                        }

                        let columns = [];
                        let tempData = [];
                        let tempObj = {};

                        let tempCheckedKeys = [...newCheckedKeys];
                        tempCheckedKeys.push(Array(maxLength + 1).join("dashify.").slice(0, -1));

                        for (let k in tempCheckedKeys) {
                            let l = tempCheckedKeys[k].split(".");
                            let title = l[maxLength - 1];

                            let colObj = {
                                title: title,
                                dataIndex: title, key: title, ellipsis: { showTitle: false },
                                render: (e) => (
                                    <Tooltip placement="topLeft" title={e}>{e}</Tooltip>
                                ),
                            }


                            if (l.length === maxLength) {
                                tempObj[title] = getKey(tempCheckedKeys[k]);
                                let tt = tempObj[title];
                                if (tt != undefined) tempObj[title] = tt.toString();

                                k = parseInt(k);
                                let l1 = tempCheckedKeys[k];

                                if (l1 !== undefined) {
                                    l1 = l1.split(".");

                                    if (hasInt === true) {
                                        let j = tempData.findIndex(e => e.key.includes(l.slice(0, l.length - 1).join(".")));
                                        if (j > -1) {
                                            tempData[j][title] = tt.toString();
                                        }
                                        else {
                                            tempObj["key"] = tempCheckedKeys[k];
                                            tempData.push(tempObj);
                                        }
                                        tempObj = {};
                                    }
                                    else if (hasInt === false && title !== "dashify") {
                                        tempObj["key"] = title;
                                        tempData[0] = tempObj;
                                    }
                                }

                                if (!columns.some(e => e.dataIndex === title)) columns.push(colObj)
                            }

                        }

                        columns = columns.filter(e => e.dataIndex !== 'dashify');
                        setParentData({ ...i, columns: columns, data: tempData });
                    }

                    else if (i.type === "chart") {
                        const chartFunc = (e) => {
                            let c = e.split(".");
                            //c.shift();
                            let m = [];
                            for (let k of c) {
                                if (/^\+?\d+$/.test(k)) break
                                m.push(k);
                            }
                            m = m.join(".");
                            let n = e.replace(m, "").split(".");
                            if (n.length > 3) n = n.slice(3, n.length).join(".");
                            else n = n.slice(2, n.length).join(".");

                            let res = [];

                            for (let k in getKey(m)) {
                                let tempK = `${m}.${k}.${n}`;
                                if (tempK[0] === ".") tempK = tempK.substring(1)
                                res.push(getKey(tempK));
                            }

                            return res;
                        }

                        let x = i.checkedKeys[0];
                        let x_vals = chartFunc(x);

                        let y_data = [];

                        for (let j = 1; j < i.checkedKeys.length; j++) {
                            let y = i.checkedKeys[j];
                            let y_vals = chartFunc(y);
                            if ((!["object", "string", "undefined"].includes(typeof y_vals[0])) && y_vals.length > 0) {
                                y_data.push({ name: y.split(".")[y.split(".").length - 1], data: y_vals });
                            }
                        }

                        let chartOptions = {
                            meta: {
                                x: x.split(".")[x.split(".").length - 1],
                                y: y_data.map(e => e.name).join(", ")
                            },
                            chart: {
                                id: 'chart',
                                redrawOnParentResize: true,
                                toolbar: {
                                    show: false
                                }
                            },
                            plotOptions: {
                                bar: {
                                    distributed: false,
                                    horizontal: false,
                                    borderRadius: 7,
                                    borderRadiusApplication: "end",
                                }
                            },
                            dataLabels: { enabled: false },

                            xaxis: { categories: x_vals },
                        };

                        setParentData({ ...i, chartOptions, chartSeries: y_data });
                    }
                })
                .catch(error => {
                    console.log(error)
                    setParentData({ id: i.id, title: i.title, type: "error", error: error.toString() });
                });
        }

        else if (i.type === "google_sheets") {
            fetch(`https://docs.google.com/spreadsheets/d/${i.sheetId}/gviz/tq?tqx=out:json&tq&gid=0`)
                .then(res => {
                    if (res.ok) {
                        return res.text();
                    } else {
                        setParentData({ id: i.id, title: i.title, type: "error", error: `Error ${res.status}` });
                        throw new Error();
                    }
                })
                .then(res => {
                    var jsonString = res.match(/(?<="table":).*(?=}\);)/g)[0]
                    var json = JSON.parse(jsonString);
                    var table = [];
                    let row = [];

                    json.cols.forEach(c => { if (c.label !== "") row.push(c.label) });
                    if (row.length !== 0) table.push(row);


                    json.rows.forEach(r => {
                        var row = []
                        r.c.forEach(cel => {
                            try { var value = cel.f ? cel.f : cel.v }
                            catch (e) { var value = '' }
                            row.push(value);
                        })
                        table.push(row);
                    })

                    let tempColumns = [];
                    let tempData = [];
                    let h = [];

                    for (let i in table[0]) {
                        h.push("h" + i.toString())
                        if (table[0][i] !== null && table[0][i].trim() !== "") tempColumns.push({
                            title: table[0][i],
                            dataIndex: "h" + i.toString(),
                            key: table[0][i],
                            ellipsis: { showTitle: false },
                            render: (e) => <Tooltip placement="topLeft" title={e} style={{ display: "block", position: "relative" }}>{e}</Tooltip>
                        });
                    }
                    let t1 = table.slice(1, table.length);
                    for (let i in t1) {
                        let t = { key: `r${parseInt(i) + 2}` };
                        for (let j in t1[i]) t[h[j]] = t1[i][j];
                        tempData.push(t);
                    }
                    setParentData({ ...i, columns: tempColumns, data: tempData });
                })
                .catch((e) => {
                    console.log(e);
                })
        }

        else if (i.type === "csv") {
            fetch(`https://oauth.usedashify.com/fetch_csv`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "csv_url": i.endpoint })
            })
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        res.json().then(r => {
                            setParentData({ id: i.id, title: i.title, type: "error", error: `Error ${r.code}: ${r.message}` });
                        })
                        throw new Error()
                    }
                })
                .then(res => {
                    let tempColumns = [];
                    let tempData = [];
                    let h = [];

                    for (let j in res[0]) {
                        h.push("h" + j.toString())
                        tempColumns.push({
                            title: res[0][j],
                            dataIndex: "h" + j.toString(),
                            key: res[0][j],
                            ellipsis: { showTitle: false },
                            render: (e) => <Tooltip placement="topLeft" title={e} style={{ display: "block", position: "relative" }}>{e}</Tooltip>
                        });
                    }
                    let t1 = res.slice(1, res.length);
                    for (let i in t1) {
                        let t = { key: `r${parseInt(i) + 2}` };
                        for (let j in t1[i]) t[h[j]] = t1[i][j];
                        tempData.push(t);
                    }

                    setParentData({ ...i, columns: tempColumns, data: tempData });
                })
                .catch((e) => {
                    console.log(e)
                })
        }

        else if (i.type === "airtable") {
            function fetch_airtable(token) {
                fetch(`https://api.airtable.com/v0/${i.base}/${i.table}`, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    redirect: 'follow'
                })
                    .then(res => {
                        if (res.ok) {
                            return res.json();
                        } else {
                            throw new Error(res.status)
                        }
                    })
                    .then(res => {
                        let tempData = [];
                        let tempColumns = [];
                        let records = res.records;

                        for (let i in records) {
                            tempData.push({ ...records[i].fields, key: i })
                            for (let j of Object.keys(records[i].fields)) {
                                if (!tempColumns.some(e => e.title === j)) tempColumns.push({ title: j, dataIndex: j, key: j })
                            }
                        }
                        setParentData({ ...i, columns: tempColumns, data: tempData });
                    })
                    .catch(error => {
                        let code = error.toString().split("Error: ");
                        code = code[code.length - 1];
                        if (code === "401") {
                            fetch("https://oauth.usedashify.com/fetch_airtable_refresh_token", {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    grant_type: "refresh_token",
                                    "refresh_token": airtableData.refresh_token,
                                    "client_id": "8714d766-0675-4c43-a0a2-2ce5441a3a26"
                                }),
                            })
                                .then(res1 => res1.json())
                                .then(res1 => {
                                    console.log("Fetched new Airtable access token")
                                    updateDoc(doc(db, "dashboards", localStorage.getItem("docId")), {
                                        airtable: {
                                            access_token: res1.access_token,
                                            refresh_token: res1.refresh_token
                                        }
                                    });
                                    fetch_airtable(res1.access_token)
                                })
                        }
                    });
            }
            fetch_airtable(airtableData.access_token);

        }

        else if (i.type === "youtube") {
            const getDateFormat = (e) => {
                let [year, month, day] = [e.year().toString(), (e.month() + 1).toString(), e.date().toString()];
                return `${year}-${month.length === 1 ? '0' + month : month}-${day.length === 1 ? '0' + day : day}`;
            }

            async function fetch_youtube(token) {
                let [d1, d2] = [null, getDateFormat(dayjs())];

                if (i.rangeType === "last_7_d") d1 = getDateFormat(dayjs().subtract(7, "day"))
                else if (i.rangeType === "last_14_d") d1 = getDateFormat(dayjs().subtract(14, "day"))
                else if (i.rangeType === "last_m") d1 = getDateFormat(dayjs().subtract(1, "month"))
                else if (i.rangeType === "last_3_m") d1 = getDateFormat(dayjs().subtract(3, "month").subtract(1, "day"))
                else if (i.rangeType === "last_y") d1 = getDateFormat(dayjs().subtract(1, "year"))
                else if (i.rangeType === "custom_timeframe") d1 = getDateFormat(dayjs().subtract(i.customRange[0], i.customRange[1]));
                else if (i.rangeType === "custom_date") [d1, d2] = [i.dateRange[0], i.dateRange[1]]


                await fetch(`https://youtubeanalytics.googleapis.com/v2/reports?startDate=${d1}&endDate=${d2}&ids=channel%3D%3DMINE&metrics=${i.metrics}&dimensions=day&sort=day`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`
                    },
                })
                    .then(r => r.json())
                    .then(async (r) => {
                        if ("error" in r) {
                            if (r.error.code === 401) {
                                await fetch(`${serverUrl}/fetch_youtube_refresh_token?refresh_token=${youtubeData.refresh_token}`)
                                    .then(r1 => r1.json())
                                    .then(r1 => {
                                        console.log("Fetched new Google access token");
                                        ytNewToken = r1.access_token;

                                        if ("error" in r1) {
                                            reauth = true;
                                            setParentData({ ...i, error: `reauth` });
                                        }
                                        else {
                                            updateDoc(doc(db, "dashboards", localStorage.getItem("docId")), {
                                                youtube: {
                                                    access_token: r1.access_token,
                                                    refresh_token: youtubeData.refresh_token
                                                }
                                            });
                                            fetch_youtube(r1.access_token)
                                        }
                                    })
                            }
                            else {
                                setParentData({ ...i, error: `Error ${r.error.code}: ${r.error.message}` });
                            }
                        }
                        else {
                            delete i["error"];

                            if (i.dataType === "table") {
                                let tempData = [];
                                let tempColumns = [];
                                let headers = [];

                                for (let i of r.columnHeaders) {
                                    tempColumns.push({ title: metricOptions[i.name], dataIndex: i.name, key: i.name })
                                    headers.push(i.name)
                                }
                                for (let i in r.rows) {
                                    let temp = { key: i };

                                    for (let j in r.rows[i]) {
                                        temp[headers[j]] = r.rows[i][j]
                                    }
                                    tempData.push(temp)
                                }
                                setParentData({ ...i, columns: tempColumns, data: tempData });
                            }

                            else if (i.dataType === "bar" || i.dataType === "line") {
                                let headers = [];
                                let x_vals = [];
                                let temp = [];

                                for (let i of r.columnHeaders) {
                                    headers.push(i.name);
                                }
                                for (let j in r.rows) {
                                    x_vals.push(r.rows[j][0]);
                                    temp.push(r.rows[j])
                                }
                                let series = headers.map((header, index) => ({ name: metricOptions[header], data: temp.map((data) => data[index]) }));
                                series = series.filter(e => e.name !== "Date");

                                let chartOptions = ({
                                    chart: {
                                        id: 'chart',
                                        redrawOnParentResize: true,
                                        toolbar: {
                                            show: false
                                        }
                                    },
                                    plotOptions: {
                                        bar: {
                                            distributed: false,
                                            horizontal: false,
                                            borderRadius: 7,
                                            borderRadiusApplication: "end",
                                        }
                                    },
                                    dataLabels: { enabled: false },

                                    xaxis: { categories: x_vals },
                                });

                                setParentData({ ...i, chartOptions, chartSeries: series });
                            }

                            else if (i.dataType === "text") {
                                const formatNumFunc = (n) => {
                                    if (n < 1e3) return n;
                                    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                                    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                                    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                                    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
                                };

                                let res = 0;
                                for (let i1 of r.rows) {
                                    res += i1[1]
                                }

                                if ("prevType" in i) {
                                    [d1, d2] = [null, null];

                                    if (i.rangeType === "last_7_d") [d1, d2] = [dayjs().subtract(14, "day"), dayjs().subtract(7, "day")]
                                    else if (i.rangeType === "last_14_d") [d1, d2] = [dayjs().subtract(28, "day"), dayjs().subtract(14, "day")]
                                    else if (i.rangeType === "last_m") [d1, d2] = [dayjs().subtract(2, "month"), dayjs().subtract(1, "month")]
                                    else if (i.rangeType === "last_3_m") [d1, d2] = [dayjs().subtract(6, "month"), dayjs().subtract(3, "month")]
                                    else if (i.rangeType === "last_y") [d1, d2] = [dayjs().subtract(2, "year"), dayjs().subtract(1, "year")]

                                    let newD = [getDateFormat(d1), getDateFormat(d2)];
                                    let prevNum = 0;

                                    await fetch(`https://youtubeanalytics.googleapis.com/v2/reports?endDate=${newD[1]}&ids=channel%3D%3DMINE&metrics=${i.metrics}&startDate=${newD[0]}&dimensions=day&sort=day`, {
                                        method: 'GET',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            "Authorization": `Bearer ${token}`
                                        },
                                    })
                                        .then(r1 => r1.json())
                                        .then(r1 => {
                                            for (let j of r1.rows) prevNum += j[1]
                                        })

                                    let diff = res - prevNum;
                                    let percentDiff = "0%";
                                    if (diff !== 0) {
                                        percentDiff = Math.round((diff / prevNum) * 100);

                                        if (Number.isFinite(percentDiff)) percentDiff += "%"
                                        else percentDiff = "100%"
                                    }

                                    if (i.textData.formatting.formatNum) res = formatNumFunc(res);
                                    setParentData({ ...i, text: res, formatting: i.textData.formatting, diff: i.prevType === "num" ? Math.round(diff) : percentDiff })
                                }
                                else {
                                    if (i.textData.formatting.formatNum) res = formatNumFunc(res);
                                    setParentData({ ...i, text: res, formatting: i.textData.formatting })
                                }


                            }
                        }
                    })
            }

            if (youtubeData.refresh_token === "None" || reauth === true) {
                setParentData({ ...i, error: 'reauth' });
            }
            else {
                if (ytNewToken !== "") await fetch_youtube(ytNewToken)
                else await fetch_youtube(youtubeData.access_token)
            }

        }

        else if (i.type === "hubspot") {
            const getKey = (key) => {
                let value = data;
                for (let segment of key.split('.')) {
                    if (typeof value === 'object' && value !== null && segment in value) value = value[segment]
                    else return undefined;
                }

                return value;
            };

            function convertEpochsToHumanReadable(obj) {
                for (let key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        let value = obj[key];
                        if (!isNaN(value) && value > 0) {
                            let date = new Date(parseInt(value));

                            let day = date.getDate().toString().padStart(2, '0');
                            let month = (date.getMonth() + 1).toString().padStart(2, '0');
                            let year = date.getFullYear();
                            let hours = date.getHours() % 12 || 12;
                            let minutes = date.getMinutes().toString().padStart(2, '0');
                            let ampm = date.getHours() < 12 ? 'AM' : 'PM';

                            obj[key] = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
                        }
                    }
                }
                return obj;
            }

            function fetch_hubspot(token) {
                fetch(`${serverUrl}/fetch_hubspot_contacts?token=${token}`)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status !== "error") {
                            let newArr = [];

                            for (let i of res.contacts) {
                                let tempId = i["identity-profiles"][0].identities;
                                for (let j of tempId) if ("is-primary" in j) tempId = j;

                                let tempProp = {};
                                for (let j in i.properties) {
                                    tempProp[j] = i.properties[j].value
                                }

                                newArr.push(convertEpochsToHumanReadable({
                                    addedAt: i.addedAt,
                                    identity: tempId.value,
                                    ...tempProp
                                }))
                            }



                            let allKeys = [...new Set(newArr.flatMap(obj => Object.keys(obj)))];
                            let tempArr = newArr.map(obj =>
                                allKeys.reduce((acc, key) => ({ ...acc, [key]: obj[key] || '' }), {})
                            );
                            let resArr = [];

                            for (let i in tempArr) {
                                resArr.push({ key: i, ...tempArr[i] })
                            }


                            let maxLength = 0;
                            let newCheckedKeys = [];
                            let hasInt = true;

                            for (let k of i.checkedKeys) {
                                let l = k.split(".");
                                l.shift();
                                if (l.length > maxLength) maxLength = l.length;
                                let t = l.slice(0, l.length - 2).join(".");
                                if (t === "") {
                                    hasInt = false;
                                    newCheckedKeys.push(l.join("."));
                                }
                                else {
                                    let n = getKey(t);

                                    if (n !== undefined) for (let j = 0; j < n.length; j++) {
                                        let m = `${t}.${j}.${l[l.length - 1]}`;
                                        if (!newCheckedKeys.includes(m)) newCheckedKeys.push(m)
                                    }
                                }
                            }

                            let columns = [];

                            let tempCheckedKeys = [...newCheckedKeys];
                            tempCheckedKeys.push(Array(maxLength + 1).join("dashify.").slice(0, -1));


                            for (let k in tempCheckedKeys) {
                                let l = tempCheckedKeys[k].split(".");
                                let title = l[maxLength - 1];

                                let colObj = {
                                    title: title,
                                    dataIndex: title, key: title, ellipsis: { showTitle: false },
                                    render: (e) => (
                                        <Tooltip placement="topLeft" title={e}>{e}</Tooltip>
                                    ),
                                }

                                if (l.length === maxLength) {
                                    if (!columns.some(e => e.dataIndex === title)) columns.push(colObj)
                                }
                            }
                            columns = columns.filter(e => e.dataIndex !== 'dashify');
                            setParentData({ ...i, columns: columns, data: resArr });

                        }
                        else {
                            fetch(`${serverUrl}/fetch_hubspot_refresh_token`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    grant_type: "refresh_token",
                                    refresh_token: hubspotData.refresh_token,
                                    client_id: "790f4b38-c4ca-45bd-8582-274f37ab254b",
                                    client_secret: "cbb4c126-0af4-4270-9f8f-888d74eedec9",
                                }),
                            })
                                .then(res1 => res1.json())
                                .then(res1 => {
                                    console.log("Fetched new Hubspot access token")
                                    updateDoc(doc(db, "dashboards", localStorage.getItem("docId")), {
                                        hubspot: {
                                            access_token: res1.access_token,
                                            refresh_token: res1.refresh_token
                                        }
                                    });
                                    fetch_hubspot(res1.access_token)
                                })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            fetch_hubspot(hubspotData.access_token);

        }

        else {
            setParentData(i);
        }
    }
}
