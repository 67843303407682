import React, { useEffect, useState } from 'react';

import { Layout, Table, Spin, Modal } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { useAuth } from "../Auth";
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import { db } from '../firebase';
import { collection, Timestamp, getDocs, query, orderBy, where } from "firebase/firestore";

import MainHeader from './Header';

export default function Admin() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dashboards, setDashboards] = useState([]);

    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        let authToken = localStorage.getItem('auth_token');
        if (!authToken) navigate('/not_found')
    }, []);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        const getFirestoreData = async () => {
            let q = query(collection(db, "accounts"), orderBy("createdAt", "desc"));
            let querySnapshot = await getDocs(q);
            let temp = [];
            let uniqueFields = new Set();

            querySnapshot.forEach((doc) => {
                let docData = doc.data();
                let formattedData = Object.keys(docData).reduce((acc, key) => {
                    uniqueFields.add(key);
                    if (docData[key] instanceof Timestamp) {
                        acc[key] = formatDate(docData[key].toDate())
                    } else {
                        acc[key] = docData[key];
                    }
                    return acc;
                }, {});

                temp.push({
                    key: doc.id,
                    ...formattedData
                })
            });
            let tempColumns = Array.from(uniqueFields).map(key => ({
                title: key,
                dataIndex: key,
                key: key,
            })).sort((a, b) => a.title.localeCompare(b.title));;

            setColumns(tempColumns);
            setData(temp);
            setLoading(false);
        }

        if (currentUser !== null) {
            if (currentUser.uid === "fMttVaDIPjXK2z04B0QSqwXTmzi2") getFirestoreData();
            else navigate('/not_found');
        }
    }, [currentUser])



    const getDashboards = async (uid) => {
        let q = query(collection(db, "dashboards"), where("owner", "==", uid));
        let querySnapshot = await getDocs(q);
        let tempBoards = [];

        querySnapshot.forEach((doc) => {
            let e = {
                id: doc.id,
                name: doc.data().name,
            };

            if (doc.data().createdAt !== undefined) e["createdAt"] = formatDate(doc.data().createdAt.toDate())
            tempBoards.push(e);
        });

        setDashboards(tempBoards);
        setIsModalOpen(true);
    }



    return (
        <Layout style={{ height: "100vh" }}>
            <Helmet>
                <title>Admin | Dashify</title>
                <meta property="og:title" content="Admin | Dashify" />
            </Helmet>

            <Layout.Header>
                <MainHeader />
            </Layout.Header>

            <Layout.Content style={{ padding: 10, height: "100%", overflow: "auto" }}>
                <h3 style={{ marginTop: 20, marginBottom: 0, fontWeight: "bold" }}>Users</h3>

                {
                    loading
                        ? <Spin size="large" />
                        : <Table columns={columns} dataSource={data} pagination={false} style={{ cursor: "pointer" }} onRow={(record, rowIndex) => {
                            return {
                                onClick: e => {
                                    getDashboards(record.userId)
                                },
                            };
                        }} />
                }

                <Modal title="Dashboards" width="50vw" style={{ overflow: "hidden" }} open={isModalOpen} footer={null} onCancel={() => { setIsModalOpen(false); setDashboards([]); }}>
                    {
                        dashboards.length > 0
                            ? dashboards.map((e, idx) =>
                                <div key={idx} style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                                    <p>{e.id} - {e.name}</p>
                                    {
                                        e.createdAt !== undefined
                                            ? <p style={{color: "#1677ff"}}>&nbsp;- {e.createdAt}</p>
                                            : null
                                    }
                                </div>
                            )
                            : <p>No Dashboards</p>
                    }
                </Modal>



            </Layout.Content>
        </Layout>
    );
};