import { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import { Modal, Button } from 'antd';

export default function WidgetSelectModal({ docId, isModalOpen, setIsModalOpen, setModalType }) {
    useEffect(() => {
        localStorage.setItem("docId", docId)
    }, [])

    return (
        <Modal title="Add Widget" width="50vw" style={{ overflow: "hidden" }} open={isModalOpen} footer={null} onCancel={() => { setIsModalOpen(false); }}>
            <Button onClick={() => { setModalType("table") }} style={{ marginRight: 10 }}>API</Button>
            <Button onClick={() => { setModalType("text") }} style={{ marginRight: 10 }}>Text</Button>
            <Button onClick={() => { setModalType("datetime") }}>Date & Time</Button>

            <h3 style={{ marginTop: 20, marginBottom: 10 }} className="font-bold text-base">Integrations</h3>

            <div style={{ marginBottom: 20 }}>
                <Button onClick={() => { setModalType("google_sheets") }} style={{ marginRight: 10 }}>Google Sheets</Button>
                <Button onClick={() => { setModalType("csv") }} style={{ marginRight: 10 }}>CSV</Button>

                <a style={{ marginRight: 10 }} href="/dashboard/integration/airtable">
                    <Button>AirTable</Button>
                </a>

                <a style={{ marginRight: 10 }} href="/dashboard/integration/youtube">
                    <Button>Youtube Analytics</Button>
                </a>

                <a href="/dashboard/integration/hubspot">
                    <Button>Hubspot</Button>
                </a>
            </div>

            <a target="_blank" href="/settings#feedback">
                <Button>Request an integration</Button>
            </a>
        </Modal>
    );
}
