import { useEffect, useState } from "react";

import MainHeader from "../../../../Header";
import { Layout, Table, Select, Button, Input, Spin, Alert } from 'antd';

import { db } from "../../../../../firebase";
import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";

export default function AirTable() {
    const [authUrl, setAuthUrl] = useState("");
    const [docId, setDocId] = useState("");
    const [fetchOptions, setFetchOptions] = useState({});
    const [loading, setLoading] = useState(false);

    const [bases, setBases] = useState([]);
    const [selectedBase, setSelectedBase] = useState("");

    const [tables, setTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState("");

    const [tableTitle, setTableTitle] = useState("");
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);

    const [editId, setEditId] = useState("");
    const [delWidgets, setDelWidgets] = useState([]);
    const [error, setError] = useState("");

    function dec2hex(dec) {
        return dec.toString(16).padStart(2, "0")
    }

    function generateId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        let t = Array.from(arr, dec2hex)
        t.splice(t.length * 0.3, 0, "-")
        t.splice(t.length * 0.6, 0, "-")
        return t.join("")
    }

    function sha256(plain) {
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return window.crypto.subtle.digest('SHA-256', data);
    }

    function base64urlencode(a) {
        return btoa(String.fromCharCode.apply(null, new Uint8Array(a)))
            .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    async function pkce_challenge_from_verifier(v) {
        let hashed = await sha256(v);
        let base64encoded = base64urlencode(hashed);
        return base64encoded;
    }

    const updateDocArr = (arr, id) => {
        return arr.filter(function (obj) {
            return obj.id !== id;
        })
    }

    useEffect(() => {
        setDocId(localStorage.getItem("docId"));
    }, [])


    useEffect(() => {
        const fetchBases = (token) => {
            let requestOptions = {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                redirect: 'follow'
            };

            setFetchOptions(requestOptions);

            fetch("https://api.airtable.com/v0/meta/bases", requestOptions)
                .then(res1 => res1.json())
                .then(res1 => {
                    let newBases = [];
                    for (let i of res1.bases) {
                        newBases.push({
                            label: i.name,
                            value: i.id
                        })
                    }
                    setLoading(false);
                    setBases(newBases);
                })
                .catch(error => console.log(error));
        }

        const fetchData = async () => {
            let docRef = doc(db, "dashboards", localStorage.getItem("docId"));
            let docSnap = await getDoc(docRef);
            let airtableData = docSnap.data().airtable;

            if (airtableData !== undefined) fetchBases(airtableData.access_token)
            else {
                let state = generateId(16);
                let code_verifier = generateId(43);
                localStorage.setItem("code_verifier", code_verifier)
                let code_challenge = await pkce_challenge_from_verifier(code_verifier);

                let options = {
                    client_id: "8714d766-0675-4c43-a0a2-2ce5441a3a26",
                    redirect_uri: "https://usedashify.com/dashboard/integration/airtable",
                    response_type: "code",
                    scope: "data.records:read schema.bases:read",
                    state: state,
                    code_challenge: code_challenge,
                    code_challenge_method: "S256",
                }

                setAuthUrl(`https://airtable.com/oauth2/v1/authorize?client_id=${options.client_id}&redirect_uri=${options.redirect_uri}&response_type=${options.response_type}&scope=${options.scope}&state=${options.state}&code_challenge=${options.code_challenge}&code_challenge_method=${options.code_challenge_method}`)
            }
        }

        let urlParams = new URLSearchParams(window.location.search);

        if (docId !== "") {
            if (urlParams.size > 0) {
                let codeData = {}
                for (let i of urlParams.entries()) {
                    codeData[i[0]] = i[1];
                }

                if ("edit" in codeData) {
                    async function fetchAirtable() {
                        let docRef = doc(db, "dashboards", docId);
                        let docSnap = await getDoc(docRef);
                        let id = codeData.id;

                        setDelWidgets(updateDocArr(docSnap.data().widgets, id));
                        fetchBases(docSnap.data().airtable.access_token);
                        setEditId(id);
                        setSelectedBase(codeData.base);
                        setSelectedTable(codeData.table);
                        setTableTitle(codeData.title);


                    }
                    fetchAirtable();
                }
                else {
                    const fetchNew = async () => {
                        let code_verifier = localStorage.getItem("code_verifier")

                        let newOptions = {
                            code: codeData.code,
                            client_id: "8714d766-0675-4c43-a0a2-2ce5441a3a26",
                            redirect_uri: "https://usedashify.com/dashboard/integration/airtable",
                            grant_type: "authorization_code",
                            code_verifier: code_verifier,
                        }

                        setLoading(true);

                        fetch(`https://oauth.usedashify.com/fetch_airtable`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(newOptions)
                        })
                            .then(res => {
                                if (res.ok) {
                                    return res.json();
                                } else {
                                    res.json().then(r => {
                                        setError(`Error ${r.error}: ${r.description}`);
                                    })
                                    throw new Error()
                                }
                            })
                            .then(res => {
                                if ("error" in res) {
                                    setError(`${res.error}: ${res.error_description}`);
                                    setLoading(false);
                                }
                                else {
                                    setError("");

                                    updateDoc(doc(db, "dashboards", docId), {
                                        airtable: {
                                            access_token: res.access_token,
                                            refresh_token: res.refresh_token
                                        }
                                    });

                                    fetchBases(res.access_token)
                                }
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }

                    fetchNew();
                }
            }
            else fetchData();
        }
    }, [docId])

    useEffect(() => {
        if (selectedBase !== "") {
            setTableColumns([]);
            setTableData([]);
            setTables([]);
            setLoading(true);

            fetch(`https://api.airtable.com/v0/meta/bases/${selectedBase}/tables`, fetchOptions)
                .then(res => res.json())
                .then(res => {
                    let newTables = [];
                    for (let i of res.tables) {
                        newTables.push({
                            label: i.name,
                            value: i.id
                        })
                    }
                    setLoading(false);
                    setTables(newTables);
                })
                .catch(error => console.log(error));
        }
    }, [selectedBase])

    useEffect(() => {
        if (selectedTable !== "") {
            setLoading(true);
            fetch(`https://api.airtable.com/v0/${selectedBase}/${selectedTable}`, fetchOptions)
                .then(res => res.json())
                .then(res => {
                    let tempData = [];
                    let tempColumns = [];
                    let records = res.records;

                    for (let i in records) {
                        tempData.push({ ...records[i].fields, key: i })

                        for (let j of Object.keys(records[i].fields)) {
                            if (!tempColumns.some(e => e.title === j)) {
                                tempColumns.push({ title: j, dataIndex: j, key: j })
                            }
                        }
                    }
                    setLoading(false);
                    setTableData(tempData);
                    setTableColumns(tempColumns);
                })
                .catch(error => console.log(error));
        }
    }, [selectedTable])

    const setData = async () => {
        if (editId !== "") {
            await updateDoc(doc(db, "dashboards", docId), {
                widgets: [...delWidgets, { type: "airtable", id: editId, title: tableTitle, base: selectedBase, table: selectedTable }]
            });
        }
        else {
            await updateDoc(doc(db, "dashboards", docId), {
                widgets: arrayUnion({
                    type: "airtable",
                    id: crypto.randomUUID().split("-")[0],
                    title: tableTitle,
                    base: selectedBase,
                    table: selectedTable,
                })
            });
        }
        window.location.href = `/dashboard/${docId}`;
    }

    return (
        <Layout style={{ height: "100vh" }}>
            <Layout.Header>
                <MainHeader />
            </Layout.Header>

            <Layout.Content>
                <div style={{ padding: 20 }}>
                    {
                        error !== ""
                            ? <Alert message="Error" description={error} type="error" style={{ marginTop: 10 }} />
                            : null
                    }

                    {
                        authUrl !== ""
                            ? <a href={authUrl}>
                                <Button type="primary">Connect AirTable</Button>
                            </a>
                            : null
                    }

                    {
                        bases.length > 0
                            ? <div>
                                Select Base:
                                <Select style={{ width: 270, marginLeft: 10, marginBottom: 20 }} onChange={(e) => { setSelectedBase(e) }} options={bases} />
                            </div>
                            : null
                    }

                    {
                        tables.length > 0
                            ? <div>
                                Select Table:
                                <Select style={{ width: 270, marginLeft: 10, marginBottom: 20 }} onChange={(e) => { setSelectedTable(e) }} options={tables} />
                            </div>
                            : null
                    }

                    {
                        loading
                            ? <Spin />
                            : null
                    }

                    {
                        tableColumns.length > 0
                            ? <div>
                                <Input style={{ marginBottom: 10 }} placeholder="Enter Widget Title" value={tableTitle} onChange={(e) => setTableTitle(e.target.value)} />

                                <Table dataSource={tableData} columns={tableColumns} />
                                <Button type="primary" style={{ marginRight: 10 }} onClick={setData}>Ok</Button>
                                <a href={`/dashboard/${docId}`}>
                                    <Button>Cancel</Button>
                                </a>
                            </div>
                            : null
                    }
                </div>
            </Layout.Content>
        </Layout>

    );
}