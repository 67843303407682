import { useEffect, useState } from "react";
import styled from "styled-components";

import { Modal, Input, Form, Button, message, Spin } from 'antd';

import { useAuth } from "../../../../Auth";
import { db } from "../../../../firebase";
import { doc, updateDoc, getDoc, query, collection, where, getDocs, arrayUnion, arrayRemove } from "firebase/firestore";

export default function AddCollab({ isModalOpen, setIsModalOpen, userTier }) {
    const { currentUser } = useAuth();
    const [collabs, setCollabs] = useState(null);

    useEffect(() => {
        const getFirestoreData = async () => {
            let docId = localStorage.getItem("docId");
            let docRef = doc(db, "dashboards", docId);
            let docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                let c = docSnap.data().collaborators;
                if (c !== undefined) {
                    let temp = [];
                    for (let i of c) {
                        let q = query(collection(db, "accounts"), where("userId", "==", i));
                        let querySnapshot = await getDocs(q);
                        querySnapshot.forEach(d => {
                            temp.push({ email: d.data().email, id: i, name: d.data().name })
                        })
                    }
                    setCollabs(temp);
                }
                else setCollabs([]);
            }
        }
        getFirestoreData()
    }, [])

    const onFinish = (e) => {
        const getFirestoreData = async () => {
            let q = query(collection(db, "accounts"), where("email", "==", e.email));
            let querySnapshot = await getDocs(q);
            if (querySnapshot.empty) message.error("No user with this email exists")
            else querySnapshot.forEach((d) => {
                if (d.data().email === currentUser.email) message.error("Cannot add yourself as a collaborator")
                else if (collabs.length === userLim()) message.error("You have reached the limit of adding collaborators")
                else {
                    let uid = d.data().userId;
                    let name = d.data().name;

                    setCollabs([...collabs, { email: e.email, id: uid, name: name }]);
                    message.success(`Added ${name} as a collaborator`)
                }
            });
        }

        if (collabs.some(e1 => e1.email === e.email)) message.error("Collaborator already added")
        else getFirestoreData()
    }

    const userLim = () => {
        let lim = 0;

        if (userTier === "starter") lim = 3
        else if (userTier === "premium") lim = 10

        return lim;
    }

    const onCancel = () => { setIsModalOpen(false); }

    const onOk = () => {
        let temp = [];
        for (let i of collabs) temp.push(i.id);

        let docId = localStorage.getItem("docId");
        updateDoc(doc(db, "dashboards", docId), { collaborators: temp });
        setIsModalOpen(false);
    }

    return (
        <Modal title="Add Collaborator" width="50vw" style={{ overflow: "hidden" }} open={isModalOpen} onCancel={onCancel} footer={[<Button key={0} type="primary" onClick={onOk}>Ok</Button>]}>
            <Form onFinish={onFinish} layout="vertical" requiredMark="optional">
                <Form.Item name="email" label="E-mail" rules={[
                    {
                        type: 'email',
                        message: 'Invalid Email',
                    },
                    {
                        required: true,
                        message: '',
                    },
                ]}>
                    <Input />
                </Form.Item>

                <Form.Item >
                    <Button type="primary" htmlType="submit">Add</Button>
                </Form.Item>
            </Form>

            {
                collabs === null
                    ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                        <Spin />
                    </div>
                    : collabs.length > 0
                        ? <div style={{ marginBottom: 20 }}>
                            <h3 style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>Collaborators</h3>
                            {
                                collabs.map((e, i) =>
                                    <div key={i} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
                                        <p>{e.email}</p>
                                        <Button danger onClick={() => { setCollabs(l => l.filter(item => item.id !== e.id)); }}>Remove</Button>
                                    </div>
                                )
                            }
                        </div>
                        : null
            }
        </Modal>
    );
}
