import React, { useState, useEffect, useRef } from 'react';

import { message } from 'antd';
import { useAuth } from '../../Auth';
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    const udHeaderRef = useRef(null);
    const logoRef = useRef(null);

    const navbarTogglerRef = useRef(null);
    const navbarCollapseRef = useRef(null);

    const handleLogout = async () => {
        try {
            await logout();
            message.success("Logged out successfully");
            navigate('/');
        } catch (error) {
            message.error("Failed to log out: " + error.message);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const sticky = udHeaderRef.current.offsetTop;

            if (window.pageYOffset > sticky) {
                udHeaderRef.current.classList.add('sticky');
                logoRef.current.src = '/banner.png';
            } else {
                udHeaderRef.current.classList.remove('sticky');
                logoRef.current.src = '/landing/banner-white.png';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const collapseNavbar = () => {
        navbarTogglerRef.current.classList.remove('navbarTogglerActive');
        navbarCollapseRef.current.classList.add('hidden');
    }

    return (
        <div ref={udHeaderRef} className="ud-header bg-primary absolute top-0 left-0 z-40 w-full flex items-center">
            <div className="container">
                <div className="flex -mx-4 items-center justify-between relative">
                    <div className="px-4 w-60 max-w-full">
                        <a href="/" className="navbar-logo w-full block py-5">
                            <img src="/landing/banner-white.png" alt="logo" ref={logoRef} className="w-full header-logo" />
                        </a>
                    </div>
                    <div className="flex px-4 justify-between items-center w-full">
                        <div>
                            <button id="navbarToggler" ref={navbarTogglerRef} onClick={() => {
                                navbarTogglerRef.current.classList.toggle('navbarTogglerActive');
                                navbarCollapseRef.current.classList.toggle('hidden');
                            }} className="block absolute right-4 top-1/2 -translate-y-1/2 lg:hidden focus:ring-2 ring-primary px-3 py-[6px]rounded-lg">
                                <span className="relative w-[30px] h-[2px] my-[6px] block bg-white"></span>
                                <span className="relative w-[30px] h-[2px] my-[6px] block bg-white"></span>
                                <span className="relative w-[30px] h-[2px] my-[6px] block bg-white"></span>
                            </button>
                            <nav id="navbarCollapse" ref={navbarCollapseRef} className="absolute py-5 lg:py-0 lg:px-4 xl:px-6 bg-white lg:bg-transparent shadow-lg rounded-lg
                                    max-w-[250px] w-full lg:max-w-full lg:w-full right-4 top-full hidden lg:block lg:static lg:shadow-none">
                                <ul className="block lg:flex">
                                    <li className="relative group">
                                        <a href="/contact" onClick={collapseNavbar}
                                            className="ud-menu-scroll text-base text-dark lg:text-white lg:group-hover:opacity-70 lg:group-hover:text-white group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0">
                                            Contact
                                        </a>
                                    </li>

                                    <li className="relative group">
                                        <a href="/pricing" onClick={collapseNavbar}
                                            className="ud-menu-scroll text-base text-dark lg:text-white lg:group-hover:opacity-70 lg:group-hover:text-white group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0">
                                            Pricing
                                        </a>
                                    </li>

                                    <li className="relative group">
                                        <a href="/blog" onClick={collapseNavbar}
                                            className="ud-menu-scroll text-base text-dark lg:text-white lg:group-hover:opacity-70 lg:group-hover:text-white group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0">
                                            Blog
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        {
                            currentUser
                                ? <div className="sm:flex justify-end hidden pr-16 lg:pr-0">
                                    <a href="/menu" className="text-base font-medium text-white hover:opacity-70 py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0">Menu</a>
                                    <button onClick={handleLogout} className="text-base font-medium text-white hover:opacity-70 py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0">Logout</button>
                                </div>
                                : <div className="sm:flex justify-end hidden pr-16 lg:pr-0">
                                    <a href="/login" className="text-base font-medium text-white hover:opacity-70 py-3 px-7 loginBtn">Login</a>
                                    <a href="/signup" className="text-base font-medium text-white bg-white bg-opacity-20 rounded-lg py-3 px-6 hover:bg-opacity-100 hover:text-dark
                                    signUpBtn duration-300 ease-in-out">Sign Up</a>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
