import React from 'react';

export default function NotFound() {
    const notFoundStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80%',
    };

    const contentStyle = {
        textAlign: 'center',
        color: '#495057',
    };

    const headingStyle = {
        fontSize: '8em',
        marginBottom: '10px',
        color: 'purple',
    };

    const paragraphStyle = {
        fontSize: '1.5em',
    };

    return (
        <div style={{ height: "100vh", backgroundColor: "#f8f9fa" }}>
            <div style={notFoundStyle}>
                <div style={contentStyle}>
                    <h1 style={headingStyle}>404</h1>
                    <p style={paragraphStyle}>Sorry, the page you are looking for does not exist</p>
                </div>
            </div>
        </div>
    );
};

