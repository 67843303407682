import { useEffect, useState } from "react";
import styled from "styled-components";

import { Modal, Input, Button, Alert, Table, Tooltip } from 'antd';

export default function GoogleSheets({ isModalOpen, setIsModalOpen, setParentData, editData }) {
    const [tableTitle, setTableTitle] = useState(editData !== null ? editData.title : "");
    const [sheetId, setSheetId] = useState(editData !== null ? editData.sheetId : "");
    const [error, setError] = useState("");
    const [tableColumns, setTableColumns] = useState(editData !== null ? editData.columns : []);
    const [tableData, setTableData] = useState(editData !== null ? editData.data : []);

    const getEndpointJson = () => {
        //let id = '1n-rjSYb63Z2jySS3-M0BQ78vu8DTPOjG-SZM4i8IxXI';
        //let id = "1w2Hho_Ou2HJLqpp65Umgl-gAxXuB3K5y5qVmo-gT25s"
        //let id = "1u6nPw5axTP-1SKcWi3prro3X1TUEoaGSZUNVzH8ykHk";
        let gid = '0';

        fetch(`https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:json&tq&gid=${gid}`)
            .then(res => {
                if (res.ok) {
                    return res.text();
                } else {
                    setError(`Error ${res.status}`);
                    throw new Error()
                }
            })
            .then(res => {
                var jsonString = res.match(/(?<="table":).*(?=}\);)/g)[0]
                var json = JSON.parse(jsonString);
                var table = [];
                let row = [];

                json.cols.forEach(c => { if (c.label !== "") row.push(c.label) });
                if (row.length !== 0) table.push(row);


                json.rows.forEach(r => {
                    var row = []
                    r.c.forEach(cel => {
                        try { var value = cel.f ? cel.f : cel.v }
                        catch (e) { var value = '' }
                        row.push(value);
                    })
                    table.push(row);
                })

                setError("");
                let tempColumns = [];
                let tempData = [];
                let h = [];

                for (let i in table[0]) {
                    h.push("h" + i.toString())
                    if(table[0][i] !== null && table[0][i].trim() !== "") tempColumns.push({
                        title: table[0][i],
                        dataIndex: "h" + i.toString(),
                        key: table[0][i],
                        ellipsis: { showTitle: false },
                        render: (e) => <Tooltip placement="topLeft" title={e} style={{ display: "block", position: "relative" }}>{e}</Tooltip>
                    });
                }
                let t1 = table.slice(1, table.length);
                for (let i in t1) {
                    let t = { key: `r${parseInt(i) + 2}` };
                    for (let j in t1[i]) t[h[j]] = t1[i][j];
                    tempData.push(t);
                }

                for (let i in tempColumns) {
                    let t = tempColumns[i].title;
                    tempColumns[i].title = <Input placeholder="Enter Column Name" value={t} bordered={false} onChange={(e) => { editColumnName(tempColumns, i, e.target.value); }} />
                }

                setTableColumns(tempColumns);
                setTableData(tempData);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const editColumnName = (arr, idx, tNew) => {
        let newArr = [...arr];
        newArr[idx]["title"] = <Input placeholder="Enter Column Name" value={tNew} bordered={false} onChange={(e) => { editColumnName(arr, idx, e.target.value); }} />;
        setTableColumns(newArr);
    }

    const onOk = () => {
        let newArr = [];
        for (let i of tableColumns) {
            newArr.push({ ...i, title: typeof i.title === "string" ? i.title : i.title.props.value })
        }

        if (editData !== null) setParentData({
            ...editData,
            title: tableTitle,
            sheetId: sheetId,
            columns: newArr,
            data: tableData,
            edit: true
        });

        else setParentData({
            type: "google_sheets",
            id: crypto.randomUUID().split("-")[0],
            title: tableTitle,
            sheetId: sheetId,
            columns: newArr,
            data: tableData
        });
        setIsModalOpen(false);
    }

    return (
        <Modal title={editData !== null ? "Edit Widget" : "Add Widget"} width="50vw" style={{ overflow: "hidden" }}
            okButtonProps={{ disabled: tableColumns.length === 0 || tableData.length === 0 }} open={isModalOpen} onOk={onOk} onCancel={() => { setIsModalOpen(false); }}>
            <Input style={{ marginBottom: 10 }} placeholder="Enter Widget Title" value={tableTitle} onChange={(e) => setTableTitle(e.target.value)} />

            <h4 style={{ marginBottom: 5 }}>Spreadsheet URL</h4>
            <Input addonBefore="https://docs.google.com/spreadsheets/d/" placeholder="Sheet ID" value={sheetId} onChange={(e) => setSheetId(e.target.value)} />

            <center style={{ marginTop: 20 }}>
                <Button onClick={getEndpointJson} disabled={sheetId.trim() === "" || sheetId.includes("/")}>Load Sheet</Button>
            </center>

            {tableColumns.length > 0
                ? <div>
                    <h4 style={{ marginBottom: 5 }}>Table Preview:</h4>
                    <Table columns={tableColumns} dataSource={tableData} />
                </div>
                : null
            }

            {
                error !== ""
                    ? <Alert message="Error" description={error} type="error" style={{ marginTop: 10 }} />
                    : null
            }

        </Modal>
    );
}
