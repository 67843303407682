import { useEffect, useState, useContext } from "react";

import { Table, Button, Input, Checkbox, Space } from 'antd';

export default function ModalTabTable({ data, setData }) {
    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Key',
            dataIndex: 'key_column',
            key: 'key_column',
            editable: true,
            render: (_, record) => <Input value={record.key_column} onChange={(e) => handleCellValueChange(e, 'key_column', record.key)} />,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            editable: true,
            render: (_, record) => <Input value={record.value} onChange={(e) => handleCellValueChange(e, 'value', record.key)} />,
        },
        {
            title: "",
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => handleDelete(record.key)}>Delete</Button>
                </Space>
            ),
        },
    ];

    const handleCellValueChange = (e, column, key) => {
        let newData = data.map((item) =>
            item.key === key ? { ...item, [column]: e.target.value } : item
        );
        setData(newData);
    };

    const handleAddRow = () => {
        let newData = [...data];
        newData.push({
            key: newData.length + 1,
            key_column: "",
            value: "",
        });
        setData(newData);
    };

    const handleDelete = (key) => {
        let newData = data.filter((item) => item.key !== key);
        setData(newData);
    };

    return (
        <div>
            <Table dataSource={data} columns={columns.filter(column => column.key !== 'key')} pagination={false} />
            <Button onClick={handleAddRow} style={{ marginTop: 10, marginBottom: 10 }}>Add</Button>
        </div>
    );
}

const TreeNode = ({ node }) => {
    const keys = Object.keys(node);

    return (
        <div>
            {keys.map((key) => (
                <div style={{ marginLeft: 30 }} key={key}>
                    <Checkbox checked style={{ marginRight: 10 }} />
                    <strong>{key}:</strong> {typeof node[key] === 'object' ? <TreeNode node={node[key]} /> : node[key]}
                </div>
            ))}
        </div>
    );
};
