import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import ContactSection from '../components/contactSection'

export default function Contact() {
    return (
        <div>
            <Helmet>
                <title>Contact | Dashify</title>
                <meta property="og:title" content="Contact | Dashify" />
            </Helmet>

            <Header />

            <ContactSection />

            <Footer />
        </div>
    )
}

