import { useEffect, useState } from "react";
import styled from "styled-components";

import { Modal, Input } from 'antd';
import TextEdit from "../TextEdit";

export default function TextModal({ isModalOpen, setIsModalOpen, setParentData, editData }) {
    const [title, setTitle] = useState(editData !== null ? editData.title : "");
    const [textData, setTextData] = useState(editData !== null ? {
        text: editData.text,
        formatting: editData.formatting
    } : {});

    const onOk = () => {
        if (editData !== null) setParentData({
            ...editData,
            ...textData,
            title,
            edit: true
        });

        else setParentData({
            type: "text",
            id: crypto.randomUUID().split("-")[0],
            title: title,
            text: textData.text,
            formatting: { textSize: textData.formatting.textSize, bold: textData.formatting.bold, color: textData.formatting.color }
        });
        setIsModalOpen(false);
    }

    return (
        <Modal title={editData !== null ? "Edit Widget" : "Add Widget"} width="50vw" style={{ overflow: "hidden" }}
            open={isModalOpen} onOk={onOk} onCancel={() => { setIsModalOpen(false); }}>

            <Input style={{ marginBottom: 10 }} placeholder="Enter Widget Title" value={title} onChange={(e) => setTitle(e.target.value)} />
            <TextEdit value={editData !== null ? editData.text : ""} setTextData={setTextData} formatting={editData !== null ? editData.formatting : null} editable={true} />

        </Modal>
    );
}
