import { useEffect, useState, useRef } from "react";
import dayjs from 'dayjs';

import { Layout, Table, Button, Input, DatePicker, Cascader, Alert, Segmented, Spin, Switch, Select, InputNumber, Radio } from 'antd';
import { AlignLeftOutlined, TableOutlined, BarChartOutlined, LineChartOutlined, CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import Chart from 'react-apexcharts'

import MainHeader from "../../../../Header";
import TextEdit from "../../TextEdit";

import { db } from "../../../../../firebase";
import { doc, updateDoc, arrayUnion, getDoc, getDocs, query, collection, where } from "firebase/firestore";


const serverUrl = "https://oauth.usedashify.com";
//const serverUrl = "http://127.0.0.1:5000";

export default function Youtube() {
    const [authUrl, setAuthUrl] = useState("temp");
    const [docId, setDocId] = useState("");
    const [rangeType, setRangeType] = useState("");
    const [dateRange, setDateRange] = useState([]);
    const [customRange, setCustomRange] = useState([3, "days"]);
    const [accessToken, setAccessToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [metrics, setMetrics] = useState("");
    const [alert, setAlert] = useState([]);
    const [editId, setEditId] = useState("");

    const [delWidgets, setDelWidgets] = useState([]);
    const [rawData, setRawData] = useState({});
    const [userTier, setUserTier] = useState("");

    const [tableTitle, setTableTitle] = useState("");
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [widgetType, setWidgetType] = useState("table");

    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [textData, setTextData] = useState({});

    const [comparePrev, setComparePrev] = useState(false);
    const [prevType, setPrevType] = useState("num");
    const [prevNum, setPrevNum] = useState(null);

    const [chartContainerDimensions, setChartContainerDimensions] = useState([400, 400]);
    const chartContainerRef = useRef();

    const metricOptions = {
        day: "Date",
        views: "Views",
        redViews: "Views by Premium Members",
        estimatedMinutesWatched: "Estimated Minutes Watched",
        estimatedRedMinutesWatched: "Estimated Minutes Watched by Premium Members",
        averageViewDuration: "Average View Duration",
        averageViewPercentage: "Average View Percentage",
        comments: "Comments",
        likes: "Likes",
        dislikes: "Dislikes",
        shares: "Shares",
        subscribersGained: "Subscribers Gained",
        subscribersLost: "Subscribers Lost",
        videosAddedToPlaylists: "Videos Added To Playlists",
        videosRemovedFromPlaylists: "Videos Removed From Playlists",
        averageTimeInPlaylist: "Average Time In Playlist",
        playlistAverageViewDuration: "Playlist Average View Duration",
        playlistEstimatedMinutesWatched: "Playlist Estimated Minutes Watched",
        playlistSaves: "Playlist Saves",
        playlistStarts: "Playlist Starts",
        playlistViews: "Playlist Views",
        viewsPerPlaylistStart: "Views Per Playlist Start",
        annotationImpressions: "Annotation Impressions",
        annotationClickableImpressions: "Annotation Clickable Impressions",
        annotationClicks: "Annotation Clicks",
        annotationClickThroughRate: "Annotation Click Through Rate",
        annotationClosableImpressions: "Annotation Closable Impressions",
        annotationCloses: "Annotation Closes",
        annotationCloseRate: "Annotation Close Rate",
        cardImpressions: "Card Impressions",
        cardClicks: "Card Clicks",
        cardClickRate: "Card Click Rate",
        cardTeaserImpressions: "Card Teaser Impressions",
        cardTeaserClicks: "Card Teaser Clicks",
        cardTeaserClickRate: "Card Teaser Click Rate",
        audienceWatchRatio: "Audience Watch Ratio",
        relativeRetentionPerformance: "Relative Retention Performance",
        estimatedRevenue: "Estimated Revenue",
        estimatedAdRevenue: "Estimated Ad Revenue",
        estimatedRedPartnerRevenue: "Estimated Red Partner Revenue",
        grossRevenue: "Gross Revenue",
        cpm: "CPM",
        adImpressions: "Ad Impressions",
        monetizedPlaybacks: "Monetized Playbacks",
        playbackBasedCpm: "Playback Based CPM"
    }

    const metricOptionsPaths = [
        {
            value: 'View Metrics',
            label: 'View Metrics',
            children: [
                { value: 'views', label: 'Views' },
                { value: 'redViews', label: 'Views by Premium Members' },
            ],
        },
        {
            value: 'Watch Time Metrics',
            label: 'Watch Time Metrics',
            children: [
                { value: 'estimatedMinutesWatched', label: 'Estimated Minutes Watched' },
                { value: 'estimatedRedMinutesWatched', label: 'Estimated Minutes Watched by Premium Members' },
                { value: 'averageViewDuration', label: 'Average View Duration' },
                { value: 'averageViewPercentage', label: 'Average View Percentage' },
            ],
        },
        {
            value: 'Engagement Metrics',
            label: 'Engagement Metrics',
            children: [
                { value: 'comments', label: 'Comments' },
                { value: 'likes', label: 'Likes' },
                { value: 'dislikes', label: 'Dislikes' },
                { value: 'shares', label: 'Shares' },
                { value: 'subscribersGained', label: 'Subscribers Gained' },
                { value: 'subscribersLost', label: 'Subscribers Lost' },
                { value: 'videosAddedToPlaylists', label: 'Videos Added To Playlists' },
                { value: 'videosRemovedFromPlaylists', label: 'Videos Removed From Playlists' },
            ],
        },
        {
            value: 'Playlist Metrics',
            label: 'Playlist Metrics',
            children: [
                { value: 'averageTimeInPlaylist', label: 'Average Time In Playlist' },
                { value: 'playlistAverageViewDuration', label: 'Playlist Average View Duration' },
                { value: 'playlistEstimatedMinutesWatched', label: 'Playlist Estimated Minutes Watched' },
                { value: 'playlistSaves', label: 'Playlist Saves' },
                { value: 'playlistStarts', label: 'Playlist Starts' },
                { value: 'playlistViews', label: 'Playlist Views' },
                { value: 'viewsPerPlaylistStart', label: 'Views Per Playlist Start' },
            ]
        },
        {
            value: 'Annotations Metrics',
            label: 'Annotations Metrics',
            children: [
                { value: 'annotationImpressions', label: 'Annotation Impressions' },
                { value: 'annotationClickableImpressions', label: 'Annotation Clickable Impressions' },
                { value: 'annotationClicks', label: 'Annotation Clicks' },
                { value: 'annotationClickThroughRate', label: 'Annotation Click Through Rate' },
                { value: 'annotationClosableImpressions', label: 'Annotation Closable Impressions' },
                { value: 'annotationCloses', label: 'Annotation Closes' },
                { value: 'annotationCloseRate', label: 'Annotation Close Rate' },
            ]
        },
        {
            value: 'Card Metrics',
            label: 'Card Metrics',
            children: [
                { value: 'cardImpressions', label: 'Card Impressions' },
                { value: 'cardClicks', label: 'Card Clicks' },
                { value: 'cardClickRate', label: 'Card Click Rate' },
                { value: 'cardTeaserImpressions', label: 'Card Teaser Impressions' },
                { value: 'cardTeaserClicks', label: 'Card Teaser Clicks' },
                { value: 'cardTeaserClickRate', label: 'Card Teaser Click Rate' },
            ]
        },
        {
            value: 'Audience Retention Metrics',
            label: 'Audience Retention Metrics',
            children: [
                { value: 'audienceWatchRatio', label: 'Audience Watch Ratio' },
                { value: 'relativeRetentionPerformance', label: 'Relative Retention Performance' },
            ]
        },
        {
            value: 'Estimated Revenue Metrics',
            label: 'Estimated Revenue Metrics',
            children: [
                { value: 'estimatedRevenue', label: 'Estimated Revenue' },
                { value: 'estimatedAdRevenue ', label: 'Estimated Ad Revenue' },
                { value: 'estimatedRedPartnerRevenue ', label: 'Estimated Red Partner Revenue' },
            ]
        },
        {
            value: 'Ad Performance Metrics',
            label: 'Ad Performance Metrics',
            children: [
                { value: 'grossRevenue', label: 'Gross Revenue' },
                { value: 'cpm', label: 'CPM' },
                { value: 'adImpressions', label: 'Ad Impressions' },
                { value: 'monetizedPlaybacks', label: 'Monetized Playbacks' },
                { value: 'playbackBasedCpm', label: 'Playback Based CPM' },
            ]
        }
    ]

    const rangeOptions = [
        { value: 'last_7_d', label: "Last 7 days" },
        { value: 'last_14_d', label: "Last 14 days" },
        { value: 'last_m', label: "Last month" },
        { value: 'last_3_m', label: "Last 3 months" },
        { value: 'last_y', label: "Last year" },
        { value: 'custom_timeframe', label: "Custom Timeframe" },
        { value: 'custom_date', label: "Custom Date" },
    ]

    useEffect(() => {
        setDocId(localStorage.getItem("docId"));
    }, [])

    const updateDocArr = (arr, id) => {
        return arr.filter(function (obj) {
            return obj.id !== id;
        })
    }

    useEffect(() => {
        let tempUrl = window.location.href;
        if (tempUrl.includes("#")) window.location.href = tempUrl.toString().replace("#", "?");
        let urlParams = new URLSearchParams(window.location.search);

        if (docId !== "") {
            const checkExists = async () => {
                let docRef = doc(db, "dashboards", docId);
                let docSnap = await getDoc(docRef);
                let ytData = docSnap.data().youtube;
                let owner = docSnap.data().owner

                let q = query(collection(db, "accounts"), where("userId", "==", owner));
                let querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    setUserTier(doc.data().tier);
                });

                if (ytData !== undefined) {
                    setAccessToken(ytData.access_token);
                    setRefreshToken(ytData.refresh_token);
                }

                if (urlParams.size > 0 || ytData !== undefined) {
                    let codeData = {};
                    for (let i of urlParams.entries()) {
                        codeData[i[0]] = i[1];
                    }

                    if ("edit" in codeData) {
                        let id = codeData.id;
                        setDelWidgets(updateDocArr(docSnap.data().widgets, id));
                        setEditId(id);
                        setTableTitle(codeData.title);
                        setMetrics(codeData.metrics);
                        setWidgetType(codeData.dataType);
                        setRangeType(codeData.rangeType);
                        setAuthUrl("");

                        if (codeData.rangeType === "custom_timeframe") setCustomRange([codeData.customRange0, codeData.customRange1])
                        else if (codeData.rangeType === "custom_date") setDateRange([codeData.date0, codeData.date1]);

                        if ("prevType" in codeData) {
                            setComparePrev(true);
                            setPrevType(codeData.prevType);
                        }

                        if (codeData.dataType === "text") {
                            setTextData({
                                formatting: {
                                    textSize: parseInt(codeData.textSize), bold: codeData.bold === "true", color: codeData.color, formatNum: (codeData.formatNum === "true")
                                }
                            })
                        }
                    }
                    else if ("reauth" in codeData && Object.keys(codeData).length === 1) {
                        setAuthUrl("https://oauth.usedashify.com/fetch_youtube_access_token?reauth=true");
                    }
                    else if (Object.keys(codeData).length >= 0) {
                        if (Object.keys(codeData).length > 0) {
                            setAccessToken(codeData.access_token);
                            setRefreshToken(codeData.refresh_token);
                        }

                        setAuthUrl("");

                        if ("reauth" in codeData) {
                            await updateDoc(doc(db, "dashboards", docId), {
                                youtube: {
                                    access_token: codeData.access_token,
                                    refresh_token: codeData.refresh_token,
                                }
                            });
                            window.location.href = `/dashboard/${docId}`;
                        }
                    }
                }
                else setAuthUrl("https://oauth.usedashify.com/fetch_youtube_access_token")
            }
            checkExists();
        }
    }, [docId])

    const getDateFormat = (e) => {
        let [year, month, day] = [e.year().toString(), (e.month() + 1).toString(), e.date().toString()];
        return `${year}-${month.length === 1 ? '0' + month : month}-${day.length === 1 ? '0' + day : day}`;
    }

    useEffect(() => {
        let newDate;

        if (rangeType === "last_7_d") newDate = dayjs().subtract(7, "day")
        else if (rangeType === "last_14_d") newDate = dayjs().subtract(14, "day")
        else if (rangeType === "last_m") newDate = dayjs().subtract(1, "month")
        else if (rangeType === "last_3_m") newDate = dayjs().subtract(3, "month")
        else if (rangeType === "last_y") newDate = dayjs().subtract(1, "year")

        if (newDate !== undefined) setDateRange([getDateFormat(newDate), getDateFormat(dayjs())]);

    }, [rangeType])

    useEffect(() => {
        if (rangeType === "custom_timeframe") {
            let [d1, d2] = [getDateFormat(dayjs().subtract(customRange[0], customRange[1])), getDateFormat(dayjs())];
            if (d1 !== d2) setDateRange([d1, d2])
        }
        else if (rangeType === "custom_date") {
            setDateRange([])
        }
    }, [customRange, rangeType])


    useEffect(() => {
        if (dateRange.length === 2 && dateRange[0] !== "" && dateRange[1] !== "" && metrics !== "") {
            const fetchData = (token) => {
                fetch(`https://youtubeanalytics.googleapis.com/v2/reports?endDate=${dateRange[1]}&ids=channel%3D%3DMINE&metrics=${metrics}&startDate=${dateRange[0]}&dimensions=day&sort=day`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`
                    },
                })
                    .then(r => r.json())
                    .then(async (r) => {
                        if ("error" in r) {
                            if (r.error.code === 401) {
                                await fetch(`${serverUrl}/fetch_youtube_refresh_token?refresh_token=${refreshToken}`)
                                    .then(r1 => r1.json())
                                    .then(r1 => {
                                        console.log("Fetched new Google access token");
                                        console.log(refreshToken);

                                        updateDoc(doc(db, "dashboards", docId), {
                                            youtube: {
                                                access_token: r1.access_token,
                                                refresh_token: refreshToken
                                            }
                                        });
                                        fetchData(r1.access_token)
                                    })
                            }
                            else {
                                setAlert([r.error.code, r.error.message]);
                            }
                        }
                        else {
                            setAlert([]);
                            setRawData(r);
                        }
                    })

            }
            fetchData(accessToken);
        }
    }, [dateRange, metrics])


    useEffect(() => {
        if (chartContainerRef.current !== undefined && chartContainerRef.current !== null) {
            setChartContainerDimensions([chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight])
        }
    }, [widgetType]);

    useEffect(() => {
        if (Object.keys(rawData).length > 0) {
            if (widgetType === "table") {
                let tempData = [];
                let tempColumns = [];
                let headers = [];

                for (let i of rawData.columnHeaders) {
                    tempColumns.push({ title: metricOptions[i.name], dataIndex: i.name, key: i.name })
                    headers.push(i.name)
                }
                for (let i in rawData.rows) {
                    let temp = { key: i };

                    for (let j in rawData.rows[i]) {
                        temp[headers[j]] = rawData.rows[i][j]
                    }
                    tempData.push(temp)
                }
                setTableData(tempData);
                setTableColumns(tempColumns);
            }

            else if (widgetType === "bar" || widgetType === "line") {
                let headers = [];
                let x_vals = [];
                let temp = [];
                let rows = rawData.rows

                for (let i of rawData.columnHeaders) {
                    headers.push(i.name);
                }
                for (let j in rows) {
                    x_vals.push(rows[j][0]);
                    temp.push(rows[j])
                }
                let series = headers.map((header, index) => ({ name: metricOptions[header], data: temp.map((data) => data[index]) }));
                series = series.filter(e => e.name !== "Date");

                setChartOptions({
                    chart: {
                        id: 'chart',
                        redrawOnParentResize: true,
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            distributed: false,
                            horizontal: false,
                            borderRadius: 7,
                            borderRadiusApplication: "end",
                        }
                    },
                    dataLabels: { enabled: false },

                    xaxis: { categories: x_vals },
                })

                setChartSeries(series);
            }
            else if (widgetType === "text") {
                let res = 0;
                for (let i of rawData.rows) {
                    res += i[1]
                }
                setTextData({ text: res, formatting: textData.formatting });
            }
        }
    }, [widgetType, rawData])

    useEffect(() => {
        if (["custom_timeframe", "custom_date"].includes(rangeType)) { setComparePrev(false); setPrevNum(null); }
        else {
            if (comparePrev === true && widgetType === "text") {
                let [d1, d2] = [null, null];

                if (rangeType === "last_7_d") [d1, d2] = [dayjs().subtract(14, "day"), dayjs().subtract(7, "day")]
                else if (rangeType === "last_14_d") [d1, d2] = [dayjs().subtract(28, "day"), dayjs().subtract(14, "day")]
                else if (rangeType === "last_m") [d1, d2] = [dayjs().subtract(2, "month"), dayjs().subtract(1, "month")]
                else if (rangeType === "last_3_m") [d1, d2] = [dayjs().subtract(6, "month"), dayjs().subtract(3, "month")]
                else if (rangeType === "last_y") [d1, d2] = [dayjs().subtract(2, "year"), dayjs().subtract(1, "year")]

                let newD = [getDateFormat(d1), getDateFormat(d2)];

                fetch(`https://youtubeanalytics.googleapis.com/v2/reports?endDate=${newD[1]}&ids=channel%3D%3DMINE&metrics=${metrics}&startDate=${newD[0]}&dimensions=day&sort=day`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${accessToken}`
                    },
                })
                    .then(r => r.json())
                    .then(r => {
                        let res = 0;
                        for (let i of r.rows) {
                            res += i[1]
                        }
                        setPrevNum(res);
                    })
            }
            else setPrevNum(null)
        }

    }, [comparePrev, rangeType])

    const appendMetrics = (e) => {
        let tempStr = "";
        for (let i of e) {
            tempStr += i[1].toString() + ","
        }
        tempStr = tempStr.slice(0, -1);
        setMetrics(tempStr);
    }

    const setData = async () => {
        if (editId !== "") {
            let temp = { type: "youtube", id: editId, title: tableTitle, dataType: widgetType, metrics, rangeType };
            if (widgetType === "text") temp["textData"] = textData;

            if (rangeType === "custom_timeframe") temp["customRange"] = customRange;
            else if (rangeType === "custom_date") temp["dateRange"] = dateRange;
            if (comparePrev) temp["prevType"] = prevType;

            await updateDoc(doc(db, "dashboards", docId), {
                widgets: [...delWidgets, temp]
            });
        }
        else {
            let temp = {
                type: "youtube",
                id: crypto.randomUUID().split("-")[0],
                title: tableTitle,
                dataType: widgetType,
                metrics,
                rangeType,
            };

            if (widgetType === "text") temp["textData"] = textData;

            if (rangeType === "custom_timeframe") temp["customRange"] = customRange;
            else if (rangeType === "custom_date") temp["dateRange"] = dateRange;

            if (comparePrev) temp["prevType"] = prevType;

            let toUpdate = {};
            if (refreshToken === "None" || refreshToken === undefined) toUpdate = {
                "youtube.access_token": accessToken,
                widgets: arrayUnion(temp)
            }
            else toUpdate = {
                youtube: {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                },
                widgets: arrayUnion(temp)
            }
            await updateDoc(doc(db, "dashboards", docId), toUpdate);
        }
        window.location.href = `/dashboard/${docId}`;
    }

    const getUrlParams = () => {
        let urlParams = new URLSearchParams(window.location.search);
        let codeData = {}
        for (let i of urlParams.entries()) {
            codeData[i[0]] = i[1];
        }
        return codeData;
    }

    const getMetricsPath = () => {
        let codeData = getUrlParams();
        if ("edit" in codeData) {
            let m = getUrlParams().metrics.split(",")

            let tempM = {};
            let finalM = [];

            for (let i of metricOptionsPaths) {
                for (let j of i.children) {
                    if (m.includes(j.value)) {
                        if (i.value in tempM) tempM[i.value].push(j.value)
                        else tempM[i.value] = [j.value]
                    }
                }
            }

            for (let [key, value] of Object.entries(tempM)) { value.forEach(v => { finalM.push([key, v]) }) }
            return finalM;
        }
        else return []
    }

    const defaultDate = () => {
        let codeData = getUrlParams();
        if ("edit" in codeData && codeData.rangeType === "custom_date") return [dayjs(codeData.date0), dayjs(codeData.date1)]
        else return [];
    }

    const renderWidget = () => {
        if (widgetType === "table")
            return <Table dataSource={tableData} columns={tableColumns} />

        else if (widgetType === "bar" || widgetType === "line")
            return <Chart options={chartOptions} series={chartSeries} type={widgetType} key={widgetType}
                width={chartContainerDimensions[0]} height={chartContainerDimensions[1]} />

        else if (widgetType === "text")
            return <div>
                {
                    ["last_7_d", "last_14_d", "last_m", "last_3_m", "last_y"].includes(rangeType)
                        ? <div style={{ display: "flex", alignItems: "center" }}>
                            Compare Against Previous Timeframe?
                            <Switch defaultChecked={comparePrev} onChange={setComparePrev} style={{ marginLeft: 10 }} />
                            {
                                comparePrev === true
                                    ? <div style={{ display: "flex", flexDirection: "row" }}>
                                        <p style={{ marginLeft: 20, marginRight: 10 }}>Comparison Type:</p>
                                        <Radio.Group onChange={(e) => { setPrevType(e.target.value) }} value={prevType}>
                                            <Radio value="num">Number</Radio>
                                            <Radio value="percent">Percentage</Radio>
                                        </Radio.Group>
                                    </div>
                                    : null
                            }
                        </div>
                        : null
                }
                {
                    comparePrev === true && userTier === "free"
                        ? <p style={{ marginTop: 10 }}>
                            This feature is not available in your plan.&nbsp;
                            <a href="/pricing" target="_blank" style={{ textDecoration: "underline", color: "#0000EE" }}>Upgrade now</a>
                            &nbsp;to use this feature
                        </p>
                        : null
                }
                <TextEdit value={textData.text ?? null} setTextData={setTextData} formatting={textData.formatting ?? null} editable={false} />
                {
                    prevNum !== null && comparePrev === true
                        ? displayDiff()
                        : null
                }
            </div>
    }

    const displayDiff = () => {
        let diff = parseInt(textData.text) - prevNum;

        if (diff === 0 || isNaN(diff)) return null
        else {
            let percentDiff = Math.round((diff / prevNum) * 100);

            if (Number.isFinite(percentDiff)) percentDiff += "%"
            else percentDiff = "100%"

            return <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ fontSize: 25, color: diff > 0 ? "#32B946" : "red", marginRight: 5 }}>
                    {prevType === "num" ? Math.round(diff) : percentDiff}</p>
                {
                    diff > 0
                        ? <CaretUpFilled style={{ fontSize: 25, color: "#32B946" }} />
                        : <CaretDownFilled style={{ fontSize: 25, color: "red" }} />
                }
            </div>;
        }
    }

    return (
        <Layout style={{ height: "100vh" }}>
            <Layout.Header>
                <MainHeader />
            </Layout.Header>

            <Layout.Content>
                <div style={{ padding: 20, height: "100%", display: "flex", flexDirection: "column" }}>
                    {
                        authUrl === "temp"
                            ? <Spin size="large" style={{ marginTop: 100 }} />
                            : authUrl !== ""
                                ? <a href={authUrl}>
                                    <Button type="primary">Connect Youtube Analytics</Button>
                                </a>
                                : <>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                                        <p style={{ marginRight: 10 }}>Timeframe: </p>

                                        <Select value={rangeType} options={rangeOptions} placeholder="Select Timeframe" onChange={(e) => { setRangeType(e); }}
                                            style={{ width: "25%", marginBottom: 10, marginRight: 20 }} />

                                        {
                                            rangeType === "custom_timeframe"
                                                ? <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                                                    <p style={{ marginRight: 10 }}>Last</p>

                                                    <InputNumber min={1} max={50} defaultValue={customRange[0]} onChange={e => { setCustomRange([e, customRange[1]]) }} />

                                                    <Select defaultValue={customRange[1]} options={[
                                                        { value: 'days', label: "Days" },
                                                        { value: 'weeks', label: "Weeks" },
                                                        { value: 'months', label: "Months" },
                                                        { value: 'years', label: "Years" },
                                                    ]} onChange={e => { setCustomRange([customRange[0], e]) }} style={{ width: 100, marginLeft: 10 }} />
                                                </div>
                                                : null
                                        }

                                        {
                                            rangeType === "custom_date"
                                                ? <DatePicker.RangePicker defaultValue={defaultDate} onChange={(e, r) => { setDateRange(r) }} style={{ width: "25%" }}
                                                    disabledDate={(e) => e && e.valueOf() > Date.now()
                                                    } />
                                                : null
                                        }
                                    </div>


                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", marginBottom: 10, marginTop: 10 }}>
                                        <p style={{ marginRight: 10 }}>Metrics: </p>

                                        <Cascader placeholder="Select Metrics" multiple maxTagCount="responsive" defaultValue={getMetricsPath} showCheckedStrategy={Cascader.SHOW_CHILD}
                                            style={{ width: "25%" }} onChange={appendMetrics} options={metricOptionsPaths} />
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", marginBottom: 10, marginTop: 10 }}>
                                        <p style={{ marginRight: 10 }}>Widget Type: </p>

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Segmented value={widgetType} onChange={setWidgetType} options={[
                                                { label: 'Text', value: 'text', icon: <AlignLeftOutlined /> },
                                                { label: 'Table', value: 'table', icon: <TableOutlined /> },
                                                { label: 'Bar Chart', value: 'bar', icon: <BarChartOutlined /> },
                                                { label: 'Line Chart', value: 'line', icon: <LineChartOutlined /> },
                                            ]} />
                                        </div>
                                    </div>
                                </>
                    }

                    {
                        alert.length > 0
                            ? <Alert style={{ marginTop: 30 }} message={`Error ${alert[0]}`} description={alert[1]} type="error" closable />
                            : null
                    }

                    <div style={{ flex: 1, overflowY: "scroll", marginTop: 10 }} ref={chartContainerRef}>
                        {
                            tableColumns.length > 0 || Object.keys(chartSeries).length > 0 || Object.keys(textData).length > 0
                                ? <>
                                    <Input style={{ marginBottom: 20, marginTop: 30 }} placeholder="Enter Widget Title" value={tableTitle} onChange={(e) => setTableTitle(e.target.value)} />
                                    {renderWidget()}
                                </>
                                : null
                        }
                    </div>

                    {
                        tableColumns.length > 0 || Object.keys(chartSeries).length > 0 || Object.keys(textData).length > 0
                            ? <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>

                                <Button type="primary" style={{ marginRight: 10 }} onClick={setData}
                                    disabled={(userTier === "free" && comparePrev === true) || metrics === ""}>Ok</Button>

                                <a href={`/dashboard/${docId}`}>
                                    <Button>Cancel</Button>
                                </a>
                            </div>
                            : null
                    }
                </div>

            </Layout.Content>
        </Layout>

    );
}