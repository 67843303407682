import React from 'react'


export default function Footer() {
  return (
    <footer className="bg-black p-10 relative z-10 wow fadeInUp" data-wow-delay=".15s">
                <div className="container text-center">
                    <div className="-mx-4 flex flex-col justify-center items-center">

                        <div className="sm:w-1/2 md:w-1/2 lg:w-4/12 xl:w-3/12 px-4">
                            <div className="w-full">
                                <a href="https://usedashify.com" className="inline-block max-w-[160px]">
                                    <img src="/banner.png" alt="logo" className="max-w-full" />
                                </a>
                            </div>
                        </div>

                        <br />

                        <div className="flex items-center">
                            <a target="_blank" href="https://twitter.com/usedashify"
                                className="text-base text-[#f3f4fe] hover:text-primary px-3">
                                Twitter
                            </a>

                            <div className="w-[1.5px] h-5 bg-[#aaa]"></div>

                            <a target="_blank" href="https://www.linkedin.com/company/usedashify"
                                className="text-base text-[#f3f4fe] hover:text-primary px-3">
                                LinkedIn
                            </a>

                            <div className="w-[1.5px] h-5 bg-[#aaa]"></div>

                            <a target="_blank" href="https://usedashify.com/privacy-policy.html"
                                className="text-base text-[#f3f4fe] hover:text-primary px-3">
                                Privacy policy
                            </a>

                            <div className="w-[1.5px] h-5 bg-[#aaa]"></div>

                            <a target="_blank" href="https://usedashify.com/terms-of-service.html"
                                className="text-base text-[#f3f4fe] hover:text-primary px-3">
                                Terms of service
                            </a>
                        </div>

                        <br />
                        <div className="h-[1px] w-full bg-[#aaa]" />
                        <br />

                        <p className="text-white">© 2024 Dashify</p>

                    </div>
                </div>
            </footer>
  )
}
