import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'

export default function GoogleDisclosure() {
    return (
        <div>
            <Helmet>
                <title>Google Limited Use Requirements Disclosure | Dashify</title>
                <meta property="og:title" content="Google Limited Use Requirements Disclosure | Dashify" />
            </Helmet>

            <Header />

            <div style={{ height: "80vh", padding: 30, paddingTop: 120 }}>
                <p>Dashify's use and transfer of information received from Google APIs to any other app will adhere to&nbsp;
                    <a style={{ color: "blue", textDecoration: "underline" }} href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>,
                    including the Limited Use requirements.</p>
            </div>

            <Footer />
        </div>
    )
}

