import React, { useState, useEffect } from 'react';

import DashboardScreen from "./views/dashboard/DashboardScreen";
import MainMenu from './views/Menu';
import NotFound from './views/NotFound';
import Admin from './views/Admin';

import Settings from './views/Settings';
import ComingSoon from './views/ComingSoon';
import AirTable from './views/dashboard/components/modals/integrations/Airtable';
import Youtube from './views/dashboard/components/modals/integrations/Youtube';
import Hubspot from './views/dashboard/components/modals/integrations/Hubspot';

import Landing from './landing/views/home';
import Pricing from './landing/views/pricing';
import Contact from './landing/views/contact';
import Billing from './landing/views/billing';

import Login from './landing/views/login';
import Signup from './landing/views/signup';

import GoogleDisclosure from './landing/views/googleDiscloure';

import BlogSelect from './landing/views/blogSelect';
import IntegrateYoutubeAnalytics from './landing/views/blogs/integrateYoutubeAnalytics';

import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom'
import { AuthProvider } from './Auth';

export default function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route element={<DashboardScreen />} path="/dashboard/:docId" />
                    <Route element={<DashboardScreen />} path="/share/:docId" />
                    <Route element={<AirTable />} path='/dashboard/integration/airtable' />
                    <Route element={<Youtube />} path='/dashboard/integration/youtube' />
                    <Route element={<Hubspot />} path='/dashboard/integration/hubspot' />

                    <Route element={<Signup />} path="/signup" />
                    <Route element={<Login />} path="/login" />
                    <Route element={<MainMenu />} path="/menu" />
                    <Route element={<Settings />} path='/settings' />
                    <Route element={<Admin />} path='/admin' />

                    <Route element={<NotFound />} path='/not_found' />
                    <Route element={<ComingSoon />} path='/coming_soon' />

                    <Route element={<Landing />} path='/' />
                    <Route element={<Pricing />} path='/pricing' />
                    <Route element={<Contact />} path='/contact' />
                    <Route element={<Billing />} path='/billing' />

                    <Route element={<GoogleDisclosure />} path='/google-limited-use-requirements-disclosure' />

                    <Route element={<BlogSelect />} path='/blog' />
                    <Route element={<IntegrateYoutubeAnalytics />} path='/blog/create-a-youTube-analytics-dashboard-to-impress-your-sponsors' />


                    <Route path='*' element={<Navigate to="/not_found" replace={true} />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
};