import { useEffect, useState } from "react";
import styled from "styled-components";

import { Input, InputNumber, Switch, ColorPicker } from 'antd';

export default function TextEdit({ value, setTextData, formatting, editable }) {
    const [text, setText] = useState(value);

    const [textSize, setTextSize] = useState(formatting !== null ? formatting.textSize : 16);
    const [bold, setBold] = useState(formatting !== null ? formatting.bold : false);
    const [color, setColor] = useState(formatting !== null ? formatting.color : '#2F2443');
    const [formatNum, setFormatNum] = useState(formatting !== null ? formatting.formatNum : false);

    useEffect(() => {
        setTextData({
            text: text,
            formatting: { textSize, bold, color, formatNum }
        });
    }, [text, textSize, bold, color, formatNum]);

    useEffect(() => {
        setText(value);
    }, [value])

    const formatNumFunc = (n) => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    };
    
    //, width: /^\d+$/.test(text) ? "min-content" : "auto"

    return (
        <>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        Font Size: <InputNumber min={16} max={40} defaultValue={textSize} onChange={setTextSize} style={{ marginLeft: 10 }} />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginLeft: 20 }}>
                        Bold: <Switch defaultChecked={bold} onChange={setBold} style={{ marginLeft: 10 }} />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginLeft: 20 }}>
                        Font Color:  <ColorPicker value={color} onChangeComplete={(e) => { setColor(e.toHexString()) }} style={{ marginLeft: 10 }} />
                    </div>

                    {
                        /^\d+$/.test(text) && text >= 1e3
                            ? <div style={{ display: "flex", alignItems: "center", marginLeft: 20 }}>
                                Format Number? <Switch defaultChecked={formatNum} onChange={setFormatNum} style={{ marginLeft: 10 }} />
                            </div>
                            : null
                    }
                </div>
            </div>

            {
                editable
                    ? <Input.TextArea style={{ fontSize: textSize, fontWeight: bold ? "bold" : "normal", color: color }}
                        value={formatNum ? formatNumFunc(text) : text} onChange={(e) => { setText(e.target.value) }} />

                    : <p style={{ fontSize: textSize, fontWeight: bold ? "bold" : "normal", color: color }}>
                        {formatNum ? formatNumFunc(text) : text}
                    </p>
            }
        </>
    );
}
