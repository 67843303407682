import React, { useEffect, useState } from 'react';
import { message, Avatar } from 'antd';
import { useAuth } from "../Auth";
import { useNavigate, Link } from 'react-router-dom';

import styled from 'styled-components';

export default function MainHeader() {
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);

    const handleLogout = async () => {
        try {
            await logout();
            message.success("Logged out successfully");
            navigate('/');
        } catch (error) {
            message.error("Failed to log out: " + error.message);
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <div style={{ float: 'right', paddingRight: '20px' }}>
                {currentUser
                    ? <div style={{ position: 'relative' }}>
                        <Avatar size="large" style={{ backgroundColor: '#87d068', marginRight: '8px', cursor: 'pointer' }} onClick={() => setShowDropdown(!showDropdown)}>
                            {currentUser.email.charAt(0).toUpperCase()}
                        </Avatar>

                        {showDropdown && (
                            <DropDownContainer>
                                <b style={{ margin: 0 }}>{currentUser.email}</b>

                                <div>
                                    <Link to="/settings" style={{ display: 'block', color: 'black', textDecoration: 'none', padding: 0, margin: 0 }}>Settings</Link>
                                </div>

                                <div>
                                    <span onClick={handleLogout} style={{ display: 'block', color: 'black', cursor: 'pointer', padding: 0 }}>Logout</span>
                                </div>
                            </DropDownContainer>
                        )}
                    </div>
                    : <Link to="/login" style={{ color: 'white', marginRight: '20px' }}>Login</Link>
                }
            </div>

            <div style={{ float: 'right', paddingRight: '20px' }}>
                <Link to="/menu" style={{ color: 'white', marginRight: '20px' }}>Menu</Link>
            </div>

            <div style={{ float: 'right', paddingRight: '20px' }}>
                <Link to="/pricing" style={{ color: 'white', marginRight: '20px' }}>Pricing</Link>
            </div>

            <Link to="/" style={{ float: "left", alignItems: "center", height: 70, display: "flex", alignItems: "center" }}>
                <img src="/banner.png" style={{ height: 40 }} />
            </Link>
        </div>
    );
};

const DropDownContainer = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 8px;
    border-radius: 4px;
    z-index: 100;
    line-height: 30px;

    & div{
        border-radius: 5px;
        padding: 5px;
    }

    & div:hover{
        background-color: #eee;
    }
`