import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Alert, Modal, message, Layout, Spin } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useAuth } from '../Auth';
import { useNavigate } from 'react-router-dom';

import { db, auth } from "../firebase";
import { collection, addDoc, writeBatch, getDocs, query, where, serverTimestamp } from "firebase/firestore";
import { updatePassword, sendPasswordResetEmail, deleteUser, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';

import MainHeader from './Header';

export default function Settings() {
    const { currentUser, logout } = useAuth();
    const [form] = Form.useForm();
    const [feedbackForm] = Form.useForm();

    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [reauthenticationVisible, setReauthenticationVisible] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [currentPlan, setCurrentPlan] = useState(null);
    const [purchaseDate, setPurchaseDate] = useState(null);
    const [cancelVisible, setCancelVisible] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        let href = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
        let element = document.getElementById(href);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        const getFirestoreData = async () => {
            let q = query(collection(db, "accounts"), where("userId", "==", currentUser.uid));
            let querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                setCurrentPlan(doc.data().tier);
                setUserDetails(doc.data());
                setPurchaseDate(doc.data().purchaseDate);
            });
        }

        if (currentUser !== null) getFirestoreData();
    }, [currentUser])

    const showReauthenticationModal = () => {
        setReauthenticationVisible(true);
    };

    const hideReauthenticationModal = () => {
        setReauthenticationVisible(false);
    };

    const handleChangePassword = async (values) => {
        const { currentPassword, newPassword } = values;

        try {
            // Re-authenticate the user with their current password before updating it
            const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
            await reauthenticateWithCredential(currentUser, credential);

            // Update the password
            await updatePassword(currentUser, newPassword);
            form.resetFields();
            Modal.success({
                title: 'Password Updated',
                content: 'Your password has been successfully updated.',
            });
        } catch (error) {
            Modal.error({
                title: 'Error',
                content: error.message,
            });
        }
    };

    const handleSendPasswordResetEmail = async () => {
        try {
            await sendPasswordResetEmail(auth, currentUser.email);
            Modal.success({
                title: 'Password Reset Email Sent',
                content: 'A password reset email has been sent to your email address.',
            });
        } catch (error) {
            Modal.error({
                title: 'Error',
                content: error.message,
            });
        }
    };

    const handleReauthentication = async (values) => {
        const { email, password } = values;
        try {
            const credential = EmailAuthProvider.credential(email, password);
            await reauthenticateWithCredential(currentUser, credential);

            hideReauthenticationModal();

            Modal.confirm({
                title: 'Delete Account',
                content: 'Are you sure you want to delete your account? All your dashboards will be deleted and this action cannot be undone.',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk: async () => {
                    try {
                        let q = query(collection(db, "dashboards"), where("owner", "==", currentUser.uid));
                        let querySnapshot = await getDocs(q);
                        const batch = writeBatch(db);

                        querySnapshot.forEach((doc) => {
                            batch.delete(doc.ref);
                        });
                        await batch.commit();

                        await deleteUser(currentUser);
                        Modal.success({
                            title: 'Account Deleted',
                            content: 'Your account has been successfully deleted.',
                        });
                        handleLogout();
                    }
                    catch (error) {
                        Modal.error({
                            title: 'Error',
                            content: error.message,
                        });
                    }
                },
            });
        }
        catch (error) {
            Modal.error({
                title: 'Error',
                content: 'Reauthentication failed. Please check your email and password.',
            });
        }
    };

    const handleDeleteAccount = async () => {
        showReauthenticationModal();
    };

    const handleFeedbackSubmit = async (values) => {
        try {
            await addDoc(collection(db, "feedbacks"), {
                userId: currentUser.uid,
                email: currentUser.email,
                feedback: values.feedback,
                createdAt: serverTimestamp(),
            });
            setFeedbackSubmitted(true);
            message.success('Feedback submitted. Thank you!');
            feedbackForm.resetFields();

        } catch (error) {
            message.error('Error submitting feedback. Please try again later.');
        }
    };

    const handleLogout = async () => {
        try {
            await logout();
            message.success("Logged out successfully");
            navigate('/');
        } catch (error) {
            message.error("Failed to log out: " + error.message);
        }
    };

    function formatDate(date) {
        date = new Date(date.seconds * 1000)

        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const dayName = days[date.getDay()];
        const day = date.getDate();
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();

        let suffix = 'th';
        if (day === 1 || day === 21 || day === 31) {
            suffix = 'st';
        } else if (day === 2 || day === 22) {
            suffix = 'nd';
        } else if (day === 3 || day === 23) {
            suffix = 'rd';
        }

        return `${dayName}, ${day}${suffix} ${monthName} ${year}`;
    }

    const cancelPlan = () => {
        Modal.confirm({
            title: 'Cancel Subscription',
            content: 'Are you sure you want to cancel your subscription? This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                const options = {
                    method: 'POST',
                    mode: "cors",
                    headers: {
                        "Authorization": "Bearer sk_cca50e074598cc8ebf7c0a8d3e46dc2e1c3334371e97232e",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        "to": "info@usedashify.com",
                        "subject": "Cancel Subscription",
                        body: `Name: ${userDetails.name}, Email: ${userDetails.email}, SubscriptionID: ${userDetails.subscriptionID}, tier: ${userDetails.tier}`,
                    })
                };
                fetch('https://api.useplunk.com/v1/send', options)
                    .then(res => res.json())
                    .then(res => {
                        if (res.success) {
                            setCancelVisible(true);
                        } else alert("Invalid Email Address")
                    })
                    .catch(err => console.error(err));
            },
        });
    }

    return (
        <Layout style={{ height: "100vh" }}>
            <Layout.Header>
                <MainHeader />
            </Layout.Header>

            <Layout.Content style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
                <Modal
                    title="Re-Authentication Required"
                    open={reauthenticationVisible}
                    onCancel={hideReauthenticationModal}
                    footer={null}
                >
                    <Form onFinish={handleReauthentication}>
                        <Form.Item style={{ marginTop: 20 }}
                            name="email"
                            rules={[
                                { required: true, message: 'Please enter your email!' },
                                { type: 'email', message: 'Invalid email address' },
                            ]}
                        >
                            <Input prefix={<MailOutlined />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please enter your password!' }]}
                        >
                            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Card title="Billing" style={{ marginTop: 50, width: "60%" }}>
                    {
                        currentPlan !== null
                            ? <>
                                <p>Current Plan:
                                    <b style={{ marginLeft: 5 }}>{currentPlan[0].toUpperCase() + currentPlan.slice(1)}</b>
                                </p>

                                {
                                    purchaseDate !== undefined
                                        ? <p style={{ marginTop: 10 }}>Purchase Date:
                                            <b style={{ marginLeft: 5 }}>{formatDate(purchaseDate)}</b>
                                        </p>
                                        : null
                                }

                                <Button style={{ padding: 0, marginTop: 10 }} type="link" href="/pricing">Upgrade Plan</Button>

                                {
                                    currentPlan !== "free"
                                        ? <Button style={{ marginLeft: 5, marginTop: 10 }} type="text" danger onClick={cancelPlan} disabled={cancelVisible}>Cancel Plan</Button>
                                        : null
                                }

                            </>
                            : <Spin />
                    }

                    {
                        cancelVisible
                            ? <p>We'll cancel your subscription as soon as possible. Kindly check your email for further steps.</p>
                            : null
                    }
                </Card>

                <Card title="Password Reset" style={{ marginTop: 50, width: "60%" }}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleChangePassword}
                        style={{ maxWidth: '300px', margin: '0 auto' }}
                    >
                        <Form.Item
                            label="Current Password"
                            name="currentPassword"
                            rules={[
                                { required: true, message: 'Please enter your current password!' },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="New Password"
                            name="newPassword"
                            rules={[
                                { required: true, message: 'Please enter your new password!' },
                                { min: 6, message: 'Password must be at least 6 characters.' },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item style={{ textAlign: "center" }}>
                            <Button type="primary" htmlType="submit">
                                Change Password
                            </Button>
                        </Form.Item>
                    </Form>

                    <div style={{ textAlign: "center" }}>
                        <Button type="link" onClick={handleSendPasswordResetEmail}>
                            Or Send Password Reset Email
                        </Button>
                    </div>
                </Card>

                <Card title="Feedback / Feature Request" style={{ marginTop: 50, width: "60%" }} id="feedback">
                    {feedbackSubmitted ? (
                        <Alert message="Feedback submitted. Thank you!" type="success" showIcon />
                    ) : (
                        <Form
                            form={feedbackForm}
                            layout="vertical"
                            onFinish={handleFeedbackSubmit}
                            style={{ width: "50%", margin: '0 auto' }}
                        >
                            <Form.Item
                                label="Enter any feedback or request for features or integrations you may have"
                                name="feedback"

                                rules={[
                                    { required: true, message: 'Please enter your feedback!' },
                                ]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item style={{ textAlign: "center" }}>
                                <Button type="primary" htmlType="submit">
                                    Submit Feedback
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                </Card>

                <Card title="Account Actions" style={{ marginTop: 50, marginBottom: 50, width: "60%" }}>
                    <Button type="primary" danger onClick={handleDeleteAccount}>
                        Delete Account
                    </Button>
                    <Button type="default" onClick={handleLogout} style={{ marginLeft: '8px' }}>
                        Log Out
                    </Button>
                </Card>
            </Layout.Content>
        </Layout>
    );
};