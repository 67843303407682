import { useEffect, useState, useRef, createRef } from "react";
import styled from "styled-components";

import { Table, Button, Popconfirm, Spin, Layout, message, Popover, Input } from 'antd';
import { EditOutlined, DeleteOutlined, CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import Chart from 'react-apexcharts'

import 'gridstack/dist/gridstack.min.css'
import { GridStack } from 'gridstack'

import MainHeader from "../Header";

import { useAuth } from "../../Auth";
import { useParams, useNavigate } from 'react-router-dom';
import { db } from "../../firebase";
import { doc, updateDoc, getDoc, query, collection, where, getDocs, deleteField } from "firebase/firestore";

import WidgetSelectModal from "./components/modals/WidgetSelectModal";
import ApiModal from "./components/modals/ApiModal";
import TextModal from "./components/modals/TextModal";
import DateTimeModal from "./components/modals/DatetimeModal";
import GoogleSheets from "./components/modals/integrations/GoogleSheets";
import Csv from "./components/modals/integrations/Csv";
import AddCollab from "./components/modals/AddCollab";

import FetchWidgetData from "./components/FetchWidgetData";


export default function DashboardScreen() {
  const [data, setData] = useState([]);
  const [dataInit, setDataInit] = useState(true);
  const [dataSources, setDataSources] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("select");
  const [hoverIdx, setHoverIdx] = useState(null);
  const [editIdx, setEditIdx] = useState(null);
  const [tempIdx, setTempIdx] = useState(null);
  const [dateData, setDateData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [layout, setLayout] = useState([]);
  const [userTier, setUserTier] = useState("");
  const [ownerTier, setOwnerTier] = useState("");
  const [dashboardName, setDashboardName] = useState("");
  const [disableView, setDisableView] = useState(false);
  const [shared, setShared] = useState(null);

  const refs = useRef({});
  const { docId } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  if (Object.keys(refs.current).length !== data.length) {
    data.forEach(({ id }) => {
      refs.current[id] = refs.current[id] || createRef();
    })
  }

  const formatDateTime = (e) => {
    let d = (new Date(new Date().toLocaleString('en', { timeZone: e })));
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let t = d.toLocaleTimeString().split(" ");

    setDateData({
      timezone: e,
      data: [t[0].split(":").slice(0, 2).join(":"), t[1], days[d.getDay()], `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`],
    });
  }

  useEffect(() => {
    let interval;
    if (dateData !== null) interval = setInterval(() => {
      formatDateTime(dateData.timezone)
    }, 1000);

    return () => { clearInterval(interval); }
  }, [dateData]);

  useEffect(() => {
    const grid = GridStack.init({
      float: true,
      cellHeight: 50,
      resizable: { handles: "ne,nw,se,sw" },
      staticGrid: getUrlType() === "share"
    })
    grid.removeAll(false);

    let tempC = 0;
    //let tempW = 0;

    const getFirestoreLayout = async () => {
      let docRef = doc(db, "dashboards", docId);
      let docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let l = docSnap.data().layout;
        //tempW = docSnap.data().widgets.length;

        for (let i of data) {
          if (l !== null && l !== undefined && l.length > 0) {
            let gridData = l.find(e => e.id === i.id);
            if (gridData !== undefined) {
              delete gridData.id;
              grid.makeWidget(refs.current[i.id].current, gridData);
            }
            else {
              grid.makeWidget(refs.current[i.id].current);
            }
          }
          else {
            grid.makeWidget(refs.current[i.id].current);
          }

          tempC += 1;
        }
      }
    }

    grid.on('added removed change', () => {
      if (tempC === data.length) {
        let tempLayout = [];

        for (let i of grid.save()) {
          if (i.content !== undefined) {
            let obId = i.content.split(">id_")[1].split("<")[0];
            delete i.content;
            if (!tempLayout.find(obj => obj.id === obId)) tempLayout.push({ ...i, id: obId });
          }
        }

        if (tempLayout.length === data.length) {
          setLayout(tempLayout);
        }
      }
    });

    if (loading === false) getFirestoreLayout();
  }, [data]);

  useEffect(() => {
    if (layout.length > 0 && getUrlType() === "dashboard") {
      updateDoc(doc(db, "dashboards", docId), {
        layout: layout
      });
    }
  }, [layout])



  useEffect(() => {
    if (!isModalOpen) {
      setModalType("select");
      setEditIdx(null);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (tempIdx !== null && tempIdx !== -1) setTimeout(() => {
      setTempIdx(-1);
    }, 1000);
  }, [tempIdx]);



  useEffect(() => {
    let tempData = [];

    for (let i of data) {
      let tempObj = { id: i.id, title: i.title, type: i.type };
      if ("error" in i) tempObj = ({ ...tempObj, error: i.error })

      if (i.type === "table") tempObj = ({ ...tempObj, endpoint: i.endpoint, checkedKeys: i.checkedKeys, fetchOptions: i.fetchOptions })
      else if (i.type === "chart") tempObj = ({ ...tempObj, endpoint: i.endpoint, chartType: i.chartType, checkedKeys: i.checkedKeys, fetchOptions: i.fetchOptions })
      else if (i.type === "text") tempObj = ({ ...tempObj, text: i.text, formatting: i.formatting })
      else if (i.type === "datetime") tempObj = ({ ...tempObj, timezone: i.timezone })
      else if (i.type === "google_sheets") tempObj = ({ ...tempObj, sheetId: i.sheetId })
      else if (i.type === "csv") tempObj = ({ ...tempObj, endpoint: i.endpoint })
      else if (i.type === "airtable") tempObj = ({ ...tempObj, base: i.base, table: i.table })

      else if (i.type === "youtube") {
        tempObj = ({ ...tempObj, metrics: i.metrics, dataType: i.dataType, rangeType: i.rangeType })

        if (i.rangeType === "custom_timeframe") tempObj["customRange"] = i.customRange
        else if (i.rangeType === "custom_date") tempObj["dateRange"] = i.dateRange

        if ("prevType" in i) { tempObj["prevType"] = i.prevType; }
        if (i.dataType === "text") tempObj["textData"] = i.textData;
      }

      else if (i.type === "hubspot") tempObj = ({ ...tempObj, metric: i.metric, checkedKeys: i.checkedKeys })

      tempData.push(tempObj);
    }


    if ((data.length > 0 || !dataInit) && getUrlType() === "dashboard") {
      updateDoc(doc(db, "dashboards", docId), {
        widgets: tempData
      });
    }
    setDataInit(false);
  }, [data]);

  useEffect(() => {
    const changeDbName = setTimeout(() => {
      if (dashboardName !== "") updateDoc(doc(db, "dashboards", docId), {
        name: dashboardName
      });
    }, 1000)

    return () => clearTimeout(changeDbName)
  }, [dashboardName])

  useEffect(() => {
    const getFirestoreData = async (unknownShare) => {
      //console.log("here")
      let docRef = doc(db, "dashboards", docId);
      let docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let tempWidgets = docSnap.data().widgets;
        setDashboardName(docSnap.data().name);

        let q = query(collection(db, "accounts"), where("userId", "==", docSnap.data().owner));
        let querySnapshot = await getDocs(q);
        querySnapshot.forEach((d) => {
          setOwnerTier(d.data().tier);
        })

        if (getUrlType() === "share" && docSnap.data().disabled === true) {
          navigate('/not_found');
        }
        else {
          let tempShared = false;

          if (!unknownShare) {
            let q = query(collection(db, "dashboards"), where("collaborators", "array-contains", currentUser.uid));
            let querySnapshot = await getDocs(q);

            querySnapshot.forEach(d => {
              if (d.id === docId) {
                tempShared = true;
                setShared(true);
              }
            })
          }

          let isOwner = docSnap.data().owner === currentUser.uid && getUrlType() === "dashboard";

          if (getUrlType() === "share" || tempShared === true || isOwner) {
            if (tempWidgets !== undefined) {
              let newData = [];
              document.title = docSnap.data().name;

              if(isOwner) setShared(false);

              FetchWidgetData(tempWidgets, docSnap.data().airtable, docSnap.data().youtube, docSnap.data().hubspot, (e) => { appendDataSources(e) }, (e) => {
                newData = [...newData, e];

                if (newData.length === tempWidgets.length) {
                  setData(newData);
                  setLoading(false);

                  for (let i of newData) {
                    if (i.type === "chart" || (i.type === "youtube" && ["bar", "line"].includes(i.dataType))) {
                      setTempIdx(i.id);
                    }
                    else if (i.type === "datetime") formatDateTime(i.timezone);
                  }
                }
                else setLoading(false);
              });
            }
            else setLoading(false);
          }
          else {
            navigate('/not_found');
          }
        }
      }
      else {
        navigate('/not_found');
      }
    }


    const getFirestoreAccountData = async () => {
      let q = query(collection(db, "accounts"), where("userId", "==", currentUser.uid));
      let querySnapshot = await getDocs(q);
      querySnapshot.forEach((d) => {
        if (d.data().delMode === true) {
          setDisableView(true);
          updateDoc(doc(db, "dashboards", docId), { disabled: true });
        }
        else updateDoc(doc(db, "dashboards", docId), { disabled: deleteField() });

        setUserTier(d.data().tier);
        getFirestoreData();
      });
    }

    let authToken = localStorage.getItem('auth_token');

    if (getUrlType() === "dashboard") {
      if (!authToken) navigate('/login')
      else { if (currentUser !== null) getFirestoreAccountData() }
    }
    else {
      if (!authToken) getFirestoreData(true)
      else { if (currentUser !== null) getFirestoreAccountData() }
    }
  }, [currentUser]);



  const appendData = (widgetData) => {
    if (widgetData.edit) {
      let tempData = [...data];
      tempData[tempData.findIndex(e => e.id === widgetData.id)] = widgetData;
      setData(tempData);
    }
    else setData([...data, widgetData]);

    if (widgetData.type === "datetime") formatDateTime(widgetData.timezone);

    setModalType("select");
    setTempIdx(widgetData.id);
  }
  const appendDataSources = (widgetData) => {
    if (!dataSources.some(e => e.endpoint === widgetData.endpoint)) setDataSources(oldSources => [...oldSources, widgetData]);
  }


  const renderModal = () => {
    if (modalType === "select") return <WidgetSelectModal docId={docId} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setModalType={setModalType} />
    else if (modalType === "table" || modalType === "chart") return <ApiModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setParentData={appendData} dataSources={dataSources} appendDataSources={appendDataSources} editData={editIdx !== null ? data[editIdx] : null} />
    else if (modalType === "text") return <TextModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setParentData={appendData} editData={editIdx !== null ? data[editIdx] : null} />
    else if (modalType === "datetime") return <DateTimeModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setParentData={appendData} editData={editIdx !== null ? data[editIdx] : null} />
    else if (modalType === "google_sheets") return <GoogleSheets isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setParentData={appendData} editData={editIdx !== null ? data[editIdx] : null} />
    else if (modalType === "csv") return <Csv isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setParentData={appendData} editData={editIdx !== null ? data[editIdx] : null} />
    else if (modalType === "collab") return <AddCollab isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} userTier={userTier} />
  }

  const renderWidget = (e) => {
    if ("error" in e) {
      if (e.error === "reauth" && e.type === "youtube") return <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        {
          getUrlType() === "dashboard"
            ? <>
              <p style={{ marginBottom: 20 }}>Unable to connect to your Youtube Account</p>
              <Button type="primary" onClick={() => { window.location.href = "/dashboard/integration/youtube?reauth=true" }}>Reauthenticate</Button>
            </>
            : <p style={{ marginBottom: 20 }}>Unable to connect to the Youtube Account.<br />Kindly contact the owner of this dashboard.</p>
        }

      </div>
      else return <p>{e.error}</p>
    }

    else {
      if (["table", "google_sheets", "csv", "airtable", "hubspot"].includes(e.type) || (e.type === "youtube" && e.dataType === "table")) return <TableContainer style={{ height: "100%", overflow: "auto" }}>
        {
          e.title.trim() !== ""
            ? <h2 style={{ margin: 0, color: "#2F2443", fontWeight: 600, fontSize: "1.3rem" }}>{e.title}</h2>
            : null
        }
        <Table columns={e.columns} dataSource={e.data} pagination={{ hideOnSinglePage: true }} scroll={{ x: 100 }} />
      </TableContainer>

      else if (e.type === "text" || (e.type === "youtube" && e.dataType === "text")) return <div style={{ height: "100%" }}>
        {
          e.title.trim() !== ""
            ? <h2 style={{ margin: 0, color: "#2F2443", fontWeight: 600, fontSize: "1.3rem" }}>{e.title}</h2>
            : null
        }
        <div style={{ position: "relative", height: e.title.trim() !== "" ? "75%" : "100%" }}>
          <p style={{ fontSize: e.formatting.textSize, fontWeight: e.formatting.bold ? "bold" : "normal", color: e.formatting.color, marginTop: 0, marginBottom: 0 }}>{e.text}</p>
          {
            "diff" in e
              ? <div style={{ display: "flex", flexDirection: "row", position: "absolute", bottom: 0, right: 0 }}>
                <p style={{ fontSize: 25, color: (e.prevType === "num" ? (e.diff > 0) : (parseInt(e.diff.split("%")[0]) > 0)) ? "#32B946" : "red", marginRight: 5 }}>
                  {e.diff}</p>
                {
                  (e.prevType === "num" ? (e.diff > 0) : (parseInt(e.diff.split("%")[0]) > 0))
                    ? <CaretUpFilled style={{ fontSize: 25, color: "#32B946" }} />
                    : <CaretDownFilled style={{ fontSize: 25, color: "red" }} />
                }
              </div>
              : null
          }
        </div>
      </div>

      else if (e.type === "chart" || (e.type === "youtube" && ["bar", "line"].includes(e.dataType))) {
        return <div style={{ height: "100%" }}>
          <h2 style={{ margin: 0, color: "#2F2443", fontWeight: 600, fontSize: "1.3rem" }}>{e.title}</h2>

          <Chart key={[e.type === "youtube" ? e.dataType : e.chartType, tempIdx]} options={e.chartOptions}
            series={e.chartSeries} type={e.type === "youtube" ? e.dataType : e.chartType} width={"100%"}
            height={e.type === "youtube" ? "90%" : "85%"} />
        </div>

      }

      else if (e.type === "datetime" && dateData !== null) {
        return <div>
          <h2 style={{ margin: 0, color: "#2F2443", fontWeight: 600, fontSize: "1.3rem" }}>{e.title}</h2>
          <div style={{ display: "flex", alignItems: "baseline", marginTop: 5, marginBottom: 10 }}>
            <p style={{ fontSize: 40, margin: 0, marginRight: 5 }}>{dateData.data[0]}</p>
            <p style={{ fontSize: 18, margin: 0 }}>{dateData.data[1]}</p>
          </div>
          <p style={{ fontSize: 18, margin: 0 }}>{dateData.data[2]}</p>
          <p style={{ fontSize: 18, margin: 0 }}>{dateData.data[3]}</p>
        </div>
      }
    }
  }

  const defaultDimensions = (t, w, extraText) => {
    if (t === "w") {
      if (w === "text" || extraText) return 2
      else if (w === "datetime") return 2
      else return 7
    }
    else if (t === "h") {
      if (w === "text" || extraText) return 2
      else if (w === "datetime") return 4
      else return 5
    }
  }

  const AddWidget = () => {
    if (disableView) message.error("You have exceeded your dashboard limit according to your plan. Upgrade your plan to add additional widgets.")
    else if (userTier === "free" && data.length >= 10) message.error("You have reached your limit of adding widgets. Upgrade your plan to add additional widgets.")
    else setIsModalOpen(true);
  }

  const getUrlType = () => {
    let url = window.location.href.split("/")
    return url[url.length - 2];
  }

  const deleteWidget = (item, i) => {
    setData(data.filter((e, idx) => idx !== i));
    setTempIdx(null);
    delete refs.current[item.id];

    let newLayout = layout.filter(e => e.id !== item.id);
    if (newLayout.length === 0) {
      setLayout(newLayout);

      updateDoc(doc(db, "dashboards", docId), {
        layout: newLayout
      });
    }
  }

  const mouseLeave = () => {
    if (getUrlType() === "dashboard") {
      setHoverIdx(null);
      setTempIdx(null);
    }
  }

  const editWidget = (item, i) => {
    if (item.type === "youtube") {
      let temp = `/dashboard/integration/youtube?edit=True&id=${item.id}&title=${item.title}&metrics=${item.metrics}&dataType=${item.dataType}&rangeType=${item.rangeType}`

      //tempObj = ({ ...tempObj, metrics: i.metrics, dataType: i.dataType, rangeType: i.rangeType })

      if (item.rangeType === "custom_timeframe") temp += `&customRange0=${item.customRange[0]}&customRange1=${item.customRange[1]}`
      else if (item.rangeType === "custom_date") temp += `&date0=${item.dateRange[0]}&date1=${item.dateRange[1]}`

      if ("prevType" in item) temp += `&prevType=${item.prevType}`

      if (item.dataType === "text") temp += "&" + Object.entries(item.textData.formatting).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
      window.location.href = temp;
    }
    else if (item.type === "airtable") {
      window.location.href = `/dashboard/integration/airtable?edit=True&id=${item.id}&title=${item.title}&table=${item.table}&base=${item.base}`
    }
    else if (item.type === "hubspot") {
      let keyList = ""
      for (let i of item.checkedKeys) keyList += (i.split("Select All.0.")[1] + ",")
      window.location.href = `/dashboard/integration/hubspot?edit=True&id=${item.id}&title=${item.title}&metric=${item.metric}&checkedKeys=${keyList.slice(0, -1)}`
    }
    else setIsModalOpen(true); setModalType(item.type); setEditIdx(i);
  }

  const addCollab = () => {
    setModalType("collab");
    setIsModalOpen(true);
  }

  return (
    <Layout>

      {
        getUrlType() === "dashboard"
          ? <Layout.Header>
            <MainHeader />
          </Layout.Header>
          : null
      }

      <Layout.Content>
        <div style={{ overflow: "auto", backgroundColor: "#DEE5F5", fontFamily: "Inter" }}>

          {renderModal()}

          {
            getUrlType() === "dashboard"
              ? <TopBar>
                <div>
                  <Button disabled={userTier === ""} type="primary" onClick={AddWidget}>Add Widget</Button>

                  {
                    shared === false && ["starter", "premium"].includes(userTier)
                      ? <Button style={{ marginLeft: 10 }} onClick={addCollab}>Add Collaborators</Button>
                      : null
                  }
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Input placeholder="Enter Dashboard Name" value={dashboardName}
                    onChange={(e) => setDashboardName(e.target.value)} style={{ marginRight: 10 }} />

                  <Popover trigger="click" title="Share URL" content={() => {
                    let shareUrl = window.location.href.replace("/dashboard", "/share");
                    return <div>
                      <a href={shareUrl} target="_blank" rel="noreferrer" style={{ color: "#1677ff" }}>{shareUrl}</a>
                    </div>
                  }
                  }>
                    <Button disabled={disableView} type="primary">Share Dashboard</Button>
                  </Popover>
                </div>

              </TopBar>
              : null
          }

          {
            loading
              ? <CenterContainer>
                <Spin size="large" />
                <h1 className="text-2xl font-bold" style={{ marginTop: 15 }}>Loading...</h1>
              </CenterContainer>
              : null
          }

          <div style={{ padding: 20, height: getUrlType() === "dashboard" ? "calc(100vh - 7rem)" : (ownerTier === "free" ? "calc(100vh - 3em)" : "100vh") }}>
            <div className="grid-stack">
              {data?.map((item, i) => {
                return (
                  <div ref={refs.current[item.id]} key={item.id} className="grid-stack-item"
                    gs-w={defaultDimensions("w", item.type, (item.type === "youtube" && item.dataType === "text"))}
                    gs-h={defaultDimensions("h", item.type, (item.type === "youtube" && item.dataType === "text"))}>

                    <WidgetContainer key={i} className="grid-stack-item-content"
                      onMouseEnter={() => { if (getUrlType() === "dashboard") setHoverIdx(i); }} onMouseLeave={mouseLeave}>
                      {
                        hoverIdx === i
                          ? <HoverContainer>
                            <Button type="text" icon={<EditOutlined />} onClick={() => { editWidget(item, i) }} disabled={disableView} />

                            <Popconfirm onConfirm={() => { deleteWidget(item, i) }} okText="Yes" cancelText="No" placement="bottomRight"
                              title="Delete Widget" description="Are you sure to delete this widget?">
                              <Button type="text" danger icon={<DeleteOutlined />} />
                            </Popconfirm>

                          </HoverContainer>
                          : null
                      }
                      <p style={{ display: "none" }}>id_{item.id}</p>

                      {renderWidget(item)}

                    </WidgetContainer>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {
          getUrlType() !== "dashboard" && ownerTier === "free"
            ? <div style={{ backgroundColor: "#DEE5F5", height: "3em", paddingLeft: "0.5em" }}>
              <a href="https://usedashify.com" target="_blank" rel="noreferrer"
                style={{ color: "rgba(151, 180, 255, 0.815)", fontSize: "2em", fontWeight: 700 }}>
                Created using Dashify
              </a>
            </div>
            : null
        }
      </Layout.Content>
    </Layout>
  );
}

const CenterContainer = styled.div`
  margin-top: 50px;
  height: 60%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
`

const HoverContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 10px;
`

const TopBar = styled.div`
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
`

const WidgetContainer = styled.div`
  background-color: white;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  user-select: none;
`

const TableContainer = styled.div`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-corner {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:vertical:hover {
    background-color: #ccc;
  }
`