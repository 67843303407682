import { useEffect, useState, useContext, useRef } from "react";
import styled from "styled-components";

import { Modal, Button, Input, Tooltip, Tabs, Radio, Alert, Tree, Table, Spin, Segmented, Dropdown, Space } from 'antd';
import { AlignLeftOutlined, TableOutlined, BarChartOutlined, DownOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts'

import ModalTabTable from "./modalTabTable";
import TextEdit from "../TextEdit";

export default function ApiModal({ isModalOpen, setIsModalOpen, setParentData, dataSources, appendDataSources, editData }) {
    const [data, setData] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [endpoint, setEndpoint] = useState(editData !== null ? editData.endpoint : "");
    const [apiType, setApiType] = useState("GET")
    const [headers, setHeaders] = useState([]);
    const [fetchOptions, setFetchOptions] = useState({});

    const [selectedBody, setSelectedBody] = useState("formdata");
    const [body, setBody] = useState([]);
    const [bodyTxt, setBodyTxt] = useState("");

    const [alert, setAlert] = useState({ visible: false })
    const [section, setSection] = useState(editData !== null ? 2 : 1);

    const [expandedKeys, setExpandedKeys] = useState(["Select All"]);
    const [checkedKeys, setCheckedKeys] = useState(editData !== null ? editData.checkedKeys : []);

    const [tableColumns, setTableColumns] = useState(editData !== null ? editData.columns : []);
    const [tableData, setTableData] = useState(editData !== null ? editData.data : []);
    const [tableTitle, setTableTitle] = useState(editData !== null ? editData.title : "");

    const [widgetType, setWidgetType] = useState(editData !== null ? editData.type : "table");
    const [textData, setTextData] = useState({});

    const [chartOptions, setChartOptions] = useState(editData !== null ? editData.chartOptions : {});
    const [chartSeries, setChartSeries] = useState(editData !== null ? editData.chartSeries : []);
    const [chartType, setChartType] = useState(editData !== null ? editData.chartType : "bar");

    const [chartContainerDimensions, setChartContainerDimensions] = useState([400, 400]);
    const chartContainerRef = useRef();

    useEffect(() => {
        if (editData !== null) {
            setDataFunctions(dataSources.find(e => e.endpoint === editData.endpoint).data)
        }
    }, [])


    
    const closeModal = () => {
        setData([]);
        setTreeData([]);
        setEndpoint("");
        setHeaders([]);
        setBody([]);
        setBodyTxt("");
        setAlert({ visible: false });
        setSection(1);
        setExpandedKeys(["Select All"]);
        setCheckedKeys([]);
        setTableColumns([]);
        setTableData([]);
        setTextData({});
        setChartOptions({});
        setChartSeries([]);
        setChartType("bar");
        setIsModalOpen(false);
    }



    const convertDataToTree = (tempData, keyPrefix = '') => {
        if (tempData !== null) return Object.keys(tempData).reduce((acc, key) => {
            const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key;
            const node = {
                title: typeof tempData[key] != 'object' && !Array.isArray(tempData[key])
                    ? <div style={{ display: "flex", flexDirection: "row" }}>
                        <b>{key}:&nbsp;</b>
                        <p style={{ margin: 0, whiteSpace: "nowrap", overflow: "hidden", width: "max-content", textOverflow: "ellipsis" }}>{tempData[key].toString()}</p>
                    </div>
                    : key,

                key: fullKey,

                children: typeof tempData[key] === 'object' || Array.isArray(tempData[key])
                    ? convertDataToTree(tempData[key], fullKey)
                    : null,
            };
            acc.push(node);
            return acc;
        }, []);
    };



    /*
    https://reqres.in/api/users

    https://api.sampleapis.com/coffee/iced
    
    https://api.useplunk.com/v1/contacts

    https://finnhub.io/api/v1/search?q=AAPL&token=cggvif9r01qv7vi3klsgcggvif9r01qv7vi3klt0

    https://api.openweathermap.org/data/2.5/forecast?lat=19&lon=73&cnt=10&appid=0acbed08482b338a220e6ba9c72d00e9
    */



    const fetchData = () => {
        setData([]);

        let tempHeaders = {};
        let tempBody;

        for (let i of headers) {
            tempHeaders[i.key_column] = i.value;
        }

        if (selectedBody === "formdata") {
            tempBody = {}
            for (let i of body) {
                tempBody[i.key_column] = i.value;
            }
            tempBody = JSON.stringify(tempBody);
        }
        else tempBody = bodyTxt;

        let options = {
            method: apiType,
            headers: tempHeaders,
            body: apiType === "POST" ? tempBody : null,
        };

        setFetchOptions(options);

        fetch(endpoint, options)
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.text().then(text => { throw new Error(text) })
                }
            })
            .then(r => {
                setDataFunctions(r);
                appendDataSources({ endpoint, data: r });
            })
            .catch(error => {
                console.log(error);
                setAlert({ visible: true, description: error.toString() })
            });
    }

    const handleExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys);
    };

    const setDataFunctions = (r) => {
        setAlert({ visible: false });
        let m = { "Select All": r };
        setTreeData(convertDataToTree(m));
        setData(m);
    }



    const handleCheck = (newCheckedKeys) => {
        if (widgetType === "table" || widgetType === "text") {
            let maxLength = 0;

            for (let i of newCheckedKeys) {
                let l = i.split(".").length;
                if (l > maxLength) maxLength = l;
            }

            for (let i of newCheckedKeys) {
                let l = i.split(".");
                if (l.length !== maxLength) newCheckedKeys = newCheckedKeys.filter(e => e !== i)
            }

            let ktc = "";
            let changeArr;
            let q;


            if (checkedKeys.length > newCheckedKeys.length) {
                changeArr = checkedKeys.filter(x => !newCheckedKeys.includes(x));
                q = "del";
            }
            else if (checkedKeys.length < newCheckedKeys.length) {
                changeArr = newCheckedKeys.filter(x => !checkedKeys.includes(x));
                q = "add";
            }


            if (q === "del") {
                for (let i of changeArr) {
                    let l = i.split(".");
                    let m = l[l.length - 1];
                    if (!/^\+?\d+$/.test(m)) ktc = m;
                    else ktc = l[l.length - 2];
                }

                for (let i of newCheckedKeys) {
                    let l = i.split(".");
                    if (l[l.length - 1] === ktc || l[l.length - 2] === ktc) newCheckedKeys = newCheckedKeys.filter(e => e !== i)
                }
            }

            else if (q === "add") {
                for (let i of changeArr) {
                    let l = i.split(".");
                    let m = l[l.length - 1];
                    if (!/^\+?\d+$/.test(m)) ktc = m;
                    else ktc = l[l.length - 2];
                }

                for (let i of changeArr) {
                    let l = i.split(".");
                    let t = l.slice(0, l.length - 2).join(".");
                    let n = getKey(t);
                    if (n !== undefined) for (let j = 0; j < n.length; j++) {
                        let m = `${t}.${j}.${l[l.length - 1]}`;
                        if (j !== parseInt(l[l.length - 2]) && !newCheckedKeys.includes(m)) newCheckedKeys.push(m)
                    }
                }
            }

            setCheckedKeys(newCheckedKeys);
            let columns = [];
            let tempData = [];

            let tempObj = {}
            let tempCheckedKeys = [...newCheckedKeys]

            tempCheckedKeys.push(Array(maxLength + 1).join("dashify.").slice(0, -1));


            for (let i in tempCheckedKeys) {
                let l = tempCheckedKeys[i].split(".");
                let title = l[maxLength - 1];

                let colObj = {
                    title: title,
                    dataIndex: title, key: title, ellipsis: { showTitle: false },
                    render: (e) => (
                        <Tooltip placement="topLeft" title={e}>{e}</Tooltip>
                    ),
                }

                if (l.length === maxLength) {
                    tempObj[title] = getKey(tempCheckedKeys[i]);
                    let tt = tempObj[title];
                    if (tt != undefined) tempObj[title] = tt.toString();

                    i = parseInt(i);
                    let l1 = tempCheckedKeys[i + 1];

                    if (l1 !== undefined) {
                        l1 = l1.split(".")
                        if (l[l.length - 2] !== l1[l1.length - 2]) {
                            let j = tempData.findIndex(e => e.key.includes(l.slice(0, l.length - 1).join(".")));
                            if (j > -1) tempData[j][title] = tt.toString();
                            else {
                                tempObj["key"] = tempCheckedKeys[i];
                                tempData.push(tempObj);
                            }
                            tempObj = {};
                        }
                    }

                    if (!columns.some(e => e.dataIndex === title)) columns.push(colObj)
                }
            }

            columns = columns.filter(e => e.dataIndex !== 'dashify');

            for (let i in columns) {
                let t = columns[i].title;
                columns[i].title = <Input placeholder="Enter Column Name" value={t} bordered={false} onChange={(e) => { editColumnName(columns, i, e.target.value); }} />
            }

            setWidgetType("table");
            setTableData(tempData);
            setTableColumns(columns);
        }


        else if (widgetType === "chart") {
            setCheckedKeys(newCheckedKeys);

            if (checkedKeys.length > 0 && newCheckedKeys.length > 1) {
                let x = checkedKeys[0];
                let x_vals = chartFunc(x);

                let y_data = [];

                for (let i = 1; i < newCheckedKeys.length; i++) {
                    let y = newCheckedKeys[i];
                    let y_vals = chartFunc(y);
                    if ((!["object", "string", "undefined"].includes(typeof y_vals[0])) && y_vals.length > 0) {
                        y_data.push({ name: y.split(".")[y.split(".").length - 1], data: y_vals });
                    }
                }

                setChartOptions({
                    meta: {
                        x: x.split(".")[x.split(".").length - 1],
                        y: y_data.map(e => e.name).join(", ")
                    },
                    chart: {
                        id: 'chart',
                        redrawOnParentResize: true,
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            distributed: false,
                            horizontal: false,
                            borderRadius: 7,
                            borderRadiusApplication: "end",
                        }
                    },
                    dataLabels: { enabled: false },

                    xaxis: { categories: x_vals },
                });

                setChartSeries(y_data);
            }
        }
    };

    const chartFunc = (e) => {
        let c = e.split(".")
        let m = []
        for (let i of c) {
            if (/^\+?\d+$/.test(i)) break
            m.push(i)
        }
        m = m.join(".")
        let n = e.replace(m, "").split(".")
        n = n.slice(2, n.length).join(".")

        let res = []

        for (let i in getKey(m)) {
            res.push(getKey(`${m}.${i}.${n}`))
        }

        return res;
    }

    useEffect(() => {
        //if (widgetType === "chart") setCheckedKeys([]);
        if (chartContainerRef.current !== undefined && chartContainerRef.current !== null) {
            setChartContainerDimensions([chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight])
        }
    }, [widgetType]);

    const editColumnName = (arr, idx, tNew) => {
        let newArr = [...arr];
        newArr[idx]["title"] = <Input placeholder="Enter Column Name" value={tNew} bordered={false} onChange={(e) => { editColumnName(arr, idx, e.target.value); }} />;
        setTableColumns(newArr);
    }


    const getKey = (key) => {
        let value = data;
        for (let segment of key.split('.')) {
            if (typeof value === 'object' && value !== null && segment in value) value = value[segment]
            else return undefined;
        }

        return value;
    };

    const changeModalSection = () => {
        if (section === 1) {
            fetchData();
            setSection(2);
        }
        else if (section === 2) {
            if (widgetType === "text") {
                if (editData !== null) setParentData({
                    type: "text",
                    id: editData.id,
                    title: tableTitle,
                    text: textData.text,
                    formatting: { textSize: textData.formatting.textSize, bold: textData.formatting.bold, color: textData.formatting.color },
                    edit: true
                })
                else setParentData({
                    type: "text",
                    id: crypto.randomUUID().split("-")[0],
                    title: tableTitle,
                    text: textData.text,
                    formatting: { textSize: textData.formatting.textSize, bold: textData.formatting.bold, color: textData.formatting.color }
                })
            }


            else if (widgetType === "table") {
                let newArr = [];
                let newKeys = [];

                for (let i of tableColumns) {
                    newArr.push({ ...i, title: typeof i.title === "string" ? i.title : i.title.props.value })
                }

                for (let i of checkedKeys) {
                    let l = i.split(".");
                    let nIdx;
                    let hasInt = false;
                    for (let j in l) {
                        if (/^\+?\d+$/.test(l[j])) {
                            nIdx = j;
                            hasInt = true;
                        }
                    }
                    if (hasInt === true) {
                        let m = l.slice(0, nIdx).join(".") + ".0.";
                        if (i.includes(m)) newKeys.push(m + i.replace(m, ""));
                    }
                    else newKeys.push(i);
                }

                if (editData !== null) setParentData({
                    ...editData,
                    title: tableTitle,
                    columns: newArr,
                    data: tableData,
                    checkedKeys: newKeys,
                    fetchOptions,
                    edit: true,
                });

                else setParentData({
                    type: "table",
                    id: crypto.randomUUID().split("-")[0],
                    title: tableTitle,
                    endpoint: endpoint,
                    columns: newArr,
                    data: tableData,
                    checkedKeys: newKeys,
                    fetchOptions,
                });
            }

            else if (widgetType === "chart") {
                if (editData !== null) setParentData({
                    ...editData,
                    title: tableTitle,
                    chartOptions,
                    chartSeries,
                    chartType,
                    checkedKeys,
                    fetchOptions,
                    edit: true
                });

                else setParentData({
                    type: "chart",
                    id: crypto.randomUUID().split("-")[0],
                    title: tableTitle,
                    endpoint: endpoint,
                    chartOptions,
                    chartSeries,
                    chartType,
                    checkedKeys,
                    fetchOptions,
                });
            }
            closeModal();
        }
    }

    const disableOk = () => {
        if (section === 1) return endpoint.length === 0
        else {
            if (widgetType === "text" || widgetType === "table") return tableColumns.length === 0 || tableData.length === 0
            else if (widgetType === "chart") return chartSeries.length === 0
        }
    }

    return (
        <Modal centered title="Add Widget" width="80vw" open={isModalOpen} onOk={changeModalSection} onCancel={closeModal}
            okButtonProps={{ disabled: disableOk() }}>
            {
                section === 1
                    ? <div>
                        {
                            dataSources.length > 0
                                ? <div>
                                    <p>Available Data Sources:</p>
                                    {
                                        dataSources.map((e, i) =>
                                            <Button key={i} style={{ marginRight: 10, marginBottom: 10, maxWidth: "100%", overflow: "hidden" }}
                                                onClick={() => { setDataFunctions(e.data); setSection(2); setEndpoint(e.endpoint); }}>{e.endpoint}</Button>
                                        )
                                    }
                                    <center style={{ marginBottom: 10 }}>OR</center>
                                </div>
                                : null
                        }


                        <Input placeholder="Enter API Endpoint" value={endpoint} onChange={(e) => setEndpoint(e.target.value)} />

                        <Radio.Group onChange={(e) => { setApiType(e.target.value) }} value={apiType} style={{ marginTop: 20 }}>
                            <Radio value="GET">GET</Radio>
                            <Radio value="POST">POST</Radio>
                        </Radio.Group>

                        <Tabs defaultActiveKey="1" items={[
                            {
                                key: '1',
                                label: 'Headers',
                                children: <ModalTabTable data={headers} setData={setHeaders} />,
                            },
                            {
                                key: '2',
                                label: 'Body',
                                children: <div>
                                    <Radio.Group onChange={(e) => { setSelectedBody(e.target.value) }} value={selectedBody} style={{ marginRight: 10 }}>
                                        <Radio value="formdata">form-data</Radio>
                                        <Radio value="raw">raw</Radio>
                                    </Radio.Group>

                                    {
                                        selectedBody === "formdata"
                                            ? <ModalTabTable data={body} setData={setBody} />
                                            : <Input.TextArea style={{ marginTop: 10, marginBottom: 10 }} value={bodyTxt} onChange={(e) => setBodyTxt(e.target.value)} />
                                    }
                                </div>
                            },
                        ]} />
                    </div>


                    : <div style={{ display: "flex", flexDirection: "row", height: "70vh" }}>
                        {
                            Object.keys(treeData).length !== 0
                                ? <div style={{ overflow: "auto", height: "100%", width: "30%" }}>
                                    <p>Check fields to be displayed</p>

                                    <Tree checkable style={{ overflow: "auto" }} treeData={treeData} expandedKeys={expandedKeys}
                                        onExpand={handleExpand} checkedKeys={checkedKeys} onCheck={handleCheck} />
                                </div>

                                : !alert.visible
                                    ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                        <Spin />
                                        <p>Loading...</p>
                                    </div>
                                    : null
                        }

                        <div style={{ width: 2, height: "100%", backgroundColor: "#eee", marginLeft: 10, marginRight: 20 }} />

                        <div style={{ overflow: "auto", height: "100%", marginBottom: 20, width: "100%" }}>
                            <Input style={{ marginBottom: 10 }} placeholder="Enter Widget Title" value={tableTitle} onChange={(e) => setTableTitle(e.target.value)} />

                            {
                                editData === null
                                    ? <Segmented value={widgetType} onChange={setWidgetType} options={[
                                        { label: 'Text', value: 'text', icon: <AlignLeftOutlined /> },
                                        { label: 'Table', value: 'table', icon: <TableOutlined /> },
                                        { label: 'Chart', value: 'chart', icon: <BarChartOutlined /> },
                                    ]} />
                                    : null
                            }

                            {(() => {
                                if (widgetType === "text")
                                    return <div>
                                        {
                                            tableColumns.length > 0
                                                ? <TextEdit value={tableData[0][tableColumns[0]["dataIndex"]]} setTextData={setTextData} formatting={null} />
                                                : <p>Select text values from the list</p>
                                        }
                                    </div>

                                else if (widgetType === "table")
                                    return <div>
                                        {
                                            tableColumns.length > 0
                                                ? <>
                                                    <p>Table Preview:</p>
                                                    <Table columns={tableColumns} dataSource={tableData} />
                                                </>
                                                : <p>Select column values from the list</p>
                                        }
                                    </div>

                                else if (widgetType === "chart")
                                    return <div ref={chartContainerRef} style={{ height: "70%" }}>
                                        {
                                            Object.keys(chartOptions).length !== 0 && chartSeries.length !== 0
                                                ? <div style={{ height: "100%" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <p style={{ marginRight: 20 }}>X Axis: {chartOptions.meta.x}</p>
                                                        <p style={{ marginRight: 20 }}>Y Axis: {chartOptions.meta.y}</p>
                                                        <p style={{ marginRight: 5 }}>Chart Type:</p>

                                                        <Dropdown menu={{
                                                            selectable: true, defaultSelectedKeys: [chartType], onSelect: (e) => { setChartType(e.key); },
                                                            items: [{ key: 'bar', label: "Bar" }, { key: 'line', label: "Line" }]
                                                        }}>

                                                            <a onClick={(e) => e.preventDefault()}>
                                                                <Space>
                                                                    <p style={{ textTransform: "capitalize", margin: 0 }}>{chartType}</p>
                                                                    <DownOutlined />
                                                                </Space>
                                                            </a>
                                                        </Dropdown>
                                                    </div>
                                                    <Chart options={chartOptions} series={chartSeries} type={chartType} key={chartType} width={chartContainerDimensions[0]} height={chartContainerDimensions[1]} />
                                                </div>
                                                : <p>Select fields for X and Y axis from a list</p>
                                        }
                                    </div>
                            })()
                            }
                        </div>

                        {
                            alert.visible
                                ? <Alert message="API Error" description={alert.description} type="error" style={{ marginTop: 10 }} />
                                : null
                        }

                    </div>
            }
        </Modal>
    );
}